.CTAScreen {
  height: 1080px;
  width: 1920px;
  transform-origin: 0 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: transparent;

  .Logo {
    z-index: 1;
    width: 35%;
    img {
      max-width: 100%;
      max-height: 80%;
    }
  }
  .content {
    z-index: 1;
    width: 50%;
    padding-left: 5%;
    & > * {
      text-align: center;
    }
    p {
      font-size: 50px;
    }
    .Social {
      flex-flow: row nowrap;
      justify-content: center;
      height: 100px;
      width: 100%;

      .icons {
        height: 100px;
        display: flex;
        align-items: center;
      }

      .hashtag {
        height: 100px;
        max-height: 100px;
        max-width: 100%;
        text-align: left;

        .BiggerText {
          display: flex;
          align-items: center;
          font-weight: 500;
        }
      }
    }
  }
}
