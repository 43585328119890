@import 'assets/scss/variables/colors.scss';

.fullscreen .MainView {
  top: 0;
  .FullscreenPage {
    position: relative;
    width: 100vw;
    height: 100vh;
  }
}
