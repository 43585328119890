//SCREEM SIZE
$tablet: 1200px;
//IMPORTANT WIDTH
$sidebar-width: 240px;
$sidebar-width-collapsed: 80px;
//HEADER
$header-height: 60px;
//WALLBAR
$wallbar-height: 170px;
//NEXTBAR
$nextStepBar-height: 50px;
//SIDEBAR
$sidebar-item-header-height: 60px;
$sidebar-item-height: 45px;
//CONTAINER
$container-max-width: 880px;
$container-large-max-width: 1024px;
$container-padding-top: 50px;
$container-padding-vertical: 10px;
