@import 'assets/scss/variables/colors.scss';

.BillingHistoryModal {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  justify-content: center;

  p {
    font-weight: 500;
  }

  .ScrollBox {
    width: 100%;
    .BillingList {
      margin-top: 20px;

      .Bill {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.5px dashed $brand-color;
        display: flex;
        padding: 10px;

        &:last-of-type {
          border-bottom: none;
        }

        span,
        time {
          font-size: 14px;
          font-weight: 500;
        }

        & > span:first-of-type {
          width: 40%;
        }
      }
    }
  }
}
