@import 'assets/scss/variables/colors.scss';
$title-background-color: #e6e6e6;

.SubTitle {
  position: relative;
  color: $brand-color;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: 0.5px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  z-index: 2;
  user-select: none;
}
