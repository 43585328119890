@import 'assets/scss/variables/colors.scss';

.WallListItem {
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.2);
  width: 340px;
  background-color: white;
  margin: 0 auto;
  height: 60px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-right: 17px;
  display: flex;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  color: $brand-color;
  border: 1px solid transparent;
  box-sizing: content-box;
  position: relative;

  &:first-of-type {
    margin-top: 5px;
  }

  &:last-of-type {
    margin-bottom: 30px;
  }

  &:focus,
  &:focus-within,
  &:hover {
    &:before {
      top: -4px;
      right: -8px;
      bottom: -4px;
      left: -8px;
      border-color: #06cadb;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    display: block;
    background: #fff;
    z-index: 0;
    border-radius: 5px;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
  }

  a {
    color: $brand-color;
    width: 100%;
    max-width: 100%;
    height: 100%;
    text-decoration: none;
    text-align: left;
    padding-left: 17px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    text-decoration: none;
    position: relative;
    z-index: 2;

    &:visited {
      color: $brand-color;
    }

    span {
      backface-visibility: hidden;

      &.wall-plan {
        margin-top: 3px;
        font-size: 11px;
        color: $black-color;
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;

        span {
          display: inline;
        }
      }
    }
  }

  svg {
    height: 22px;
    cursor: pointer;
    position: relative;
    z-index: 2;

    &:hover {
      color: darken($danger-color, 15%) !important;
    }
  }
}
