@import 'assets/scss/variables/colors.scss';
.WallsList {
  width: 100%;
  background: linear-gradient(200deg, #be43ec 0%, #6c1dcb 50%) center bottom;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .WallsListContainer {
    width: 400px;
    overflow: auto;
    position: relative;
  }
  &:after {
    position: absolute;
    bottom: 70px;
    right: 0;
    left: 0;
    width: 400px;
    margin: auto;
    height: 30px;
    content: '';
    z-index: 2;
    pointer-events: none;
    background: linear-gradient(to bottom, transparent 0%, #6c1dcb 100%);
  }
}
