@import 'assets/scss/variables/colors.scss';

.PayPage .ActivationInfo {
  /* .PayPageBox {
    &.Activation {
    //   min-height: 180px;
    }

    &.Price {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  } */

  .PaySection {
    display: flex;
    // margin-bottom: 20px;

    & .Label {
      letter-spacing: 0.5px;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600;
      align-items: center;
      display: flex;
    }

    &.CouponShown .Label {
      align-content: flex-start;
    }

    &.CouponShown .PaySectionPrice .Price {
      margin-bottom: 0;
    }

    .PaySectionPrice {
      display: flex;
      flex-grow: 1;
      align-items: flex-end;
      flex-flow: column;

      .Price {
        color: $brand-color;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 10px;
        font-size: 18px;
      }

      .a {
        align-self: flex-end;
      }
    }
  }

  .Toggle {
    margin: 0 auto;
  }

  .Row {
    height: auto;
  }

  .PickerContainer {
    position: relative;
    height: 38px;

    svg {
      position: absolute;
      top: 11px;
      right: 8px;
      color: $brand-color !important;
    }

    input[type='text'] {
      color: $black-color !important;
      border-radius: 15px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid $brand-color;
      height: 38px;
      padding-left: 8px;
      width: 140px;
      text-transform: lowercase;
    }
    .react-datepicker-popper {
      z-index: 1001;
      min-width: 100%;

      &[data-placement^='bottom'] .react-datepicker__triangle {
        border-bottom-color: $brand-color;
      }

      .react-datepicker {
        color: $black-color !important;

        border-color: $brand-color;
        .react-datepicker__header {
          background-color: $brand-color;
          border-bottom: 1px solid $brand-color;
          .react-datepicker__current-month,
          .react-datepicker-time__header,
          .react-datepicker__day-name,
          .react-datepicker__day,
          .react-datepicker__time-name {
            color: #fff;
          }
        }
      }

      .react-datepicker__navigation--previous,
      .react-datepicker__navigation--next {
        width: 8px;
        height: 8px;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        transform: rotate(-225deg);
        margin-top: 2px;
      }

      .react-datepicker__navigation--previous {
        transform: rotate(-225deg);
        margin-left: 10px;
      }
      .react-datepicker__navigation--next {
        transform: rotate(-45deg);
        margin-right: 10px;
      }

      .react-datepicker--time-only {
        width: 100%;
        .react-datepicker__time-container {
          width: 100%;

          .react-datepicker__time-box {
            width: 100%;
            ul.react-datepicker__time-list li.react-datepicker__time-list-item {
              padding: 0;
              line-height: 30px;
            }
          }
        }
      }
      .react-datepicker__day--selected,
      .react-datepicker__time-list-item--selected {
        background-color: $brand-color !important;
      }
    }
  }

  .TimezonePicker {
    height: 38px;
    width: 100%;
    margin: 0 auto;

    input {
      color: $black-color !important;
      border-radius: 15px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid $brand-color;
      height: 38px;
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
      padding-left: 8px;
      width: 100%;
      text-transform: lowercase;
    }

    .timezone-picker-textfield {
      width: 100%;
      height: 100%;
    }

    .timezone-picker-list {
      background-color: white;
      z-index: 4;
      top: 5px;
      border: 1px solid #7600d2;
      margin: 0;
      border-radius: 4px;
      font-family: 'Montserrat', sans-serif;

      .timezone-picker-list-item {
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        font-family: 'Montserrat', sans-serif;
        &.timezone-picker-list-item-active {
          background-color: $brand-color;
          color: #fff;
        }
      }
    }
  }
}
