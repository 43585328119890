@import 'assets/scss/variables/zindex.scss';

.LowerBanner {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 77px;
  z-index: -1;

  svg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}
