@import 'assets/scss/variables/zindex.scss';

.SelectPlanList {
  width: 100%;
  position: absolute;
  z-index: $z-index-select-plan-list;
  text-align: center;
  background-color: #f5f5f5;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 100%;
  transition: all 0.3s ease-in-out;
  height: calc(100% - 60px);
  overflow: hidden;

  &.visible {
    top: 60px;
  }

  .BackButton {
    margin-left: 0;
    top: 40px;
    position: absolute;
    left: 40px;
  }

  > .Title {
    margin-bottom: 80px;
    @media (max-height: 800px) {
      margin-bottom: 50px;
    }
  }

  > .Button.Next {
    width: 180px;
  }

  .SelectPlanListItemsContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    top: 0;
    min-height: 390px;
    margin-bottom: 50px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
