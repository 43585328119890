@import 'assets/scss/variables/colors.scss';

.ChipsContainer {
  padding-top: 45px;
  padding-bottom: 45px;
  width: 310px;
  position: relative;
  display: flex;
  flex-direction: column;

  .Title {
    text-align: center;
    font-size: 19px;
  }

  .Input {
    position: relative;
    display: flex;
    align-items: center;

    input[type='text'] {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .ChipsContainerAddItem {
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  .InputError {
    width: 100%;
    left: 0;
    top: 43px;
  }

  .Input.HasError {
    .ChipsContainerAddItem {
      display: none !important;
    }
  }

  .ErrorCheckmark {
    top: 13px;
  }
}
.ChipsContainer-list {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  letter-spacing: 0.5px;
  max-height: 120px;
  overflow-y: scroll;
}

.Chip {
  border-radius: 25px;
  padding: 0 8px 0 13px;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 30px;
  line-height: 30px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  background-color: $chip-bg;
  transition: background 0.1s ease-in-out;
  cursor: default;
  user-select: none;

  &:hover {
    background-color: $chip-bg-hover;
  }

  .text {
    margin-right: 10px;
  }

  svg {
    cursor: pointer;
  }

  .ModalButtonWrapper {
    display: inline-flex;
  }
}
