@import 'assets/scss/variables/colors.scss';

.ChangePasswordModal {
  display: flex;
  align-items: center;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;

    .Input {
      margin-bottom: 35px;
      width: 300px;
    }
  }
}
