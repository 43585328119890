@import 'assets/scss/variables/colors.scss';

.ModerationCardContainer {
  overflow: hidden;
}

.ModerationCard {
  box-shadow: $box-shadow;
  width: 400px;
  position: relative;
  padding: 15px;
  border-radius: 10px;

  margin: 30px auto;
  &:first-of-type {
    margin-top: 0;
  }
  &.HasError {
    background-color: rgba($danger-color, 0.15);
  }
  .ModerationCard-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
    line-height: 1.42;
    .ModerationCard-social {
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        color: #3b3b3b;
        margin-right: 10px;
      }

      .ModerationCard-fetch-time {
        display: flex;
        flex-flow: row nowrap;
        font-weight: 600;
        font-size: 11px;
      }
    }

    .ModerationCard-user-info {
      div:first-child {
        margin-bottom: 2px;
        max-width: 200px;
        word-break: break-word;
        font-size: 12px;
        font-weight: 500;
      }
      div:last-child {
        font-weight: 500;
        font-size: 11px;
        color: #737373;
      }
    }
  }
  .ModerationCard-content {
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    display: flex;
    .TruncatedText {
      color: $light-grey-color;
      cursor: pointer;
    }
    .ModerationCard-media {
      margin-right: 15px;
      user-select: none;
      cursor: pointer;
      position: relative;
      > img,
      div.missing-cover {
        width: 120px;
        height: 120px;
        display: flex;
        object-fit: cover;
        min-height: 0;
      }
      div.missing-cover {
        background-color: $brand-color;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
      }
      .action-icon {
        svg {
          z-index: 1;
          width: 25px;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.57);
        }
        transition: all 0.3s ease-in-out;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        width: 120px;
        height: 120px;
        align-items: center;
        justify-content: center;
        opacity: 0;
        svg g path {
          stroke: #fff;
        }
      }
      & .action-icon.video {
        opacity: 0.5;
      }
      &:hover .action-icon {
        opacity: 1;
      }
    }
  }
  button.Read-More-Link {
    color: $brand-color;
    text-align: right;
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    background: transparent;
    border: none;
    text-decoration: underline;
  }
  .ReadMoreContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    min-height: 16px;
    cursor: pointer;
    user-select: none;
  }
  .Actions {
    display: flex;
    justify-content: space-between;
    .ActionsContainer {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &.ButtonAlone {
      display: flex;
      justify-content: center;
    }
    .Button {
      width: 48%;
    }
  }
  .ModerationCard-content {
    position: relative;
    word-break: break-word;
    .TruncatedTextWarning {
      position: absolute;
      bottom: -18px;
      background-color: rgba($black-color, 0.9);
      z-index: 1;
      border-radius: 20px;
      color: #fff;
      width: 230px;
      text-align: center;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      user-select: none;
    }
    .Arrow {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid rgba($black-color, 0.9);
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
    .ArrowContainer {
      position: fixed;
      height: 60px;
      background-color: rgba($black-color, 0.9);
      width: 100%;
      padding: 13px;
      border-radius: 10px;
    }
    span.TruncatedText:hover + .TruncatedTextWarning {
      display: block;
      opacity: 1;
    }
  }
}
