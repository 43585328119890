.FadeScreen {
  text-align: center;
  height: 1080px;
  width: 1920px;
  transform-origin: 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: transparent;

  .CardsContainer {
    position: relative;
    width: 100%;
    height: 100%;

    .Card {
      position: absolute;

      .media {
        position: relative;
        overflow: hidden;

        img,
        video {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          object-fit: cover;
        }

        img {
          object-position: center;
        }
      }
    }
  }

  &.Mosaic1 {
    .TopBar {
      height: 17.6%;
      width: 100%;
    }

    .CardsContainer {
      height: 82.4%;

      .Card {
        display: flex;
        align-items: center;
        justify-content: center;

        .media {
          width: 100%;
          height: 100%;
        }

        .content {
          display: none;
        }
      }
    }
  }

  &.Masonry1 {
    .Logo {
      position: absolute;
      left: 41.15%;
      top: 31.3%;
      width: 17.29%;
      height: 28.5%;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;

      img {
        max-width: 80%;
        max-height: 90%;
      }
    }

    .Social {
      position: absolute;
      top: 66.66%;
      left: 11.93%;
      height: 33.33%;
      width: 18.08%;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      .icons {
        max-height: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        svg {
          margin: 0 15px 15px 15px;
        }
      }

      .hashtag {
        max-width: 60%;
        max-height: 50%;
        min-height: auto;
      }

      p {
        letter-spacing: normal;
        font-size: 2em;
        margin-top: 1em;
        margin-bottom: 0;
      }
    }

    .Card {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      .media {
        width: 100%;
        height: 100%;
      }

      .content {
        display: none;
      }
    }
  }

  &.PicturePostOnly1 {
    .TopBar {
      height: 17.6%;
      width: 100%;
    }

    .CardsContainer {
      height: 82.4%;

      .Card {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .media {
          width: 44%;
          height: 85%;
          margin-right: 4%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            position: relative;
          }
        }

        .content {
          width: 44%;
          height: 80%;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;

          img {
            border-radius: 50%;
            width: 150px;
            height: 150px;
            object-fit: contain;
            border: solid 2px white;
            order: 1;
            margin-bottom: 7%;
          }

          .BiggerText {
            order: 2;
            max-height: 45%;
            line-height: 1.2;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
          }

          .name {
            order: 3;
            margin-top: 7%;
            font-size: 2em;
            font-weight: 300;

            p:first-child {
              display: none;
            }

            p:last-child {
              margin: 0;
            }
          }

          .source {
            display: none;
          }
        }
      }
    }
  }

  &.PicturePostOnly2 {
    .CardsContainer {
      height: 100%;

      .Card {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .media {
          width: 50%;
          height: 100%;
          margin-right: 5%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: relative;
          }
        }

        .content {
          width: 40%;
          margin-right: 5%;
          height: 100%;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;

          img {
            border-radius: 50%;
            width: 150px;
            height: 150px;
            border: solid 2px white;
            order: 1;
            margin-bottom: 7%;
          }

          .BiggerText {
            order: 2;
            max-height: 45%;
            line-height: 1.2;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
          }

          .name {
            order: 3;
            margin-top: 7%;
            font-size: 2em;
            font-weight: 300;

            p:first-child {
              display: none;
            }

            p:last-child {
              margin: 0;
            }
          }

          .source {
            display: none;
          }
        }
      }
    }
  }

  &.TextPic1 {
    .TopBar {
      height: 17.6%;
      width: 100%;
    }

    .CardsContainer {
      height: 82.4%;

      .Card {
        display: flex;
        align-items: center;
        justify-content: center;

        .profil {
          display: none;
        }

        &.grid-0,
        &.grid-1 {
          &.img {
            .media {
              width: 48%;
              height: 90%;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .content {
              width: 48%;
              height: 90%;
              display: flex;
              flex-flow: row wrap;
              align-items: center;
              justify-content: space-around;
              padding: 0 30px;

              .name {
                order: 2;
                height: 10%;
                width: calc(100% - 30px);
                display: flex;
                flex-flow: row;
                justify-content: flex-start;
                align-items: center;

                p {
                  margin: 0;

                  &:first-child {
                    font-size: 18px;
                    font-weight: 600;
                    margin-left: 10px;
                  }

                  &:last-child {
                    font-size: 14px;
                    opacity: 0.51;
                    margin-left: 10px;
                  }
                }
              }

              .BiggerText {
                order: 3;
                text-align: left;
                max-height: 85%;
                display: block;
                width: 100%;
              }

              .source {
                width: 30px;
                order: 1;
                height: 10%;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }

          &.txt {
            .media {
              display: none;
            }

            .content {
              width: 96%;
              height: 90%;
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: space-around;
              padding: 0 30px;

              .name {
                height: 20%;
                width: 100%;
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;

                p {
                  margin: 0;

                  &:first-child {
                    font-size: 26px;
                    font-weight: 600;
                  }

                  &:last-child {
                    margin-top: 5px;
                    font-size: 18px;
                    opacity: 0.51;
                  }
                }
              }

              .BiggerText {
                margin-bottom: 25px;
                margin-top: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                max-height: 60%;
              }

              .source {
                height: 25%;
                width: 100%;
                font-size: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }

        &.grid-2 {
          &.txt {
            .media {
              display: none;
            }

            .content {
              width: 96%;
              height: 90%;
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: space-around;
              padding: 0 30px;

              .name {
                height: 20%;
                width: 100%;
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;

                p {
                  margin: 0;

                  &:first-child {
                    font-size: 26px;
                    font-weight: 600;
                  }

                  &:last-child {
                    margin-top: 5px;
                    font-size: 18px;
                    opacity: 0.51;
                  }
                }
              }

              .BiggerText {
                margin-bottom: 25px;
                margin-top: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                max-height: 60%;
              }

              .source {
                height: 25%;
                width: 100%;
                font-size: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }

          &.img {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;

            .media {
              height: 89%;
              width: 96%;
            }

            .content {
              height: 6%;
              width: 96%;
              display: flex;
              flex-flow: row-reverse;
              align-items: center;
              justify-content: flex-end;
              padding-top: 15px;

              .name {
                display: flex;
                flex-flow: row;

                p {
                  margin: 0;

                  &:first-child {
                    font-size: 24px;
                    font-weight: 600;
                    margin-left: 15px;
                  }

                  &:last-child {
                    margin-left: 15px;
                    margin-top: 5px;
                    font-size: 18px;
                    opacity: 0.51;
                  }
                }
              }

              .source {
                font-size: 30px;
              }

              .BiggerText {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
