@import 'assets/scss/variables/colors.scss';
.FloatingButton {
  width: 32px;
  height: 32px;
  position: relative;
  border: 2px solid $brand-color;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

  svg {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
  }
  .content {
    position: absolute;
    width: 2px;
    height: 28px;
    top: 1px;
    left: -68px;
    overflow: hidden;
    transition: width 0s linear 0.5s;
    z-index: -1;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &.active .content,
  &:hover .content {
    width: 82px;
    transition: none;
  }
  .label {
    position: absolute;
    top: 3px;
    left: 100px;
    width: 82px;
    height: 22px;
    color: white;
    border-radius: 12px;
    overflow: hidden;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    z-index: 1;
    transition: all 0.3s ease-in-out 0.1s;
    padding-right: 5px;
  }
  &.active .label,
  &:hover .label {
    left: 0;
  }
  & .label {
    background-color: $brand-color;
  }
  &.valid {
    border-color: $info-color;
    .label {
      background-color: $info-color;
    }
  }
  &.danger {
    border-color: $danger-color;
    .label {
      background-color: $danger-color;
    }
  }
  &.warning {
    border-color: $warning-color;
    .label {
      background-color: $warning-color;
    }
  }
  /* REFRESH MODERATION */
  .refresh-button {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: none !important;

      &.sync-icon {
        display: block !important;
      }
    }
    &.refreshing {
      svg.sync-icon {
        animation: rotating 1.5s linear infinite;
        animation-direction: reverse;
      }
    }
    &.done {
      svg {
        display: none !important;
        &.check-icon {
          display: block !important;
        }
      }
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg) translatez(0);
  }
  to {
    transform: rotate(-360deg) translatez(0);
  }
}
