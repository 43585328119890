.EmbedPage {
  margin-top: 100px;
  .link {
    font-weight: 600;
    background: #f3f3f3;
    border: 1px solid #d0d0d0;
    padding: 10px 15px;
    border-radius: 4px;
    color: #333;
  }
  .main-container-inner {
    display: flex;
    justify-content: center;
    flex-flow: column;
  }

  .CopyToClipboardButton {
    margin-top: 20px;
    text-align: center;
  }
  p {
    letter-spacing: 0.5px;
  }

  .CopyToClipboardButton {
    position: relative;

    .ToolTip {
      position: absolute;
      height: 30px;
      background-color: rgba(24, 24, 24, 0.9);
      bottom: 100%;
      width: 180px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: 10px;
      letter-spacing: 0.5px;
      font-size: 13px;
      transition: all 0.3s ease-in-out;
      opacity: 0;

      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        transform-origin: center;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid rgba(24, 24, 24, 0.9);
      }
    }

    &:hover .ToolTip {
      opacity: 1;
    }
  }
}
