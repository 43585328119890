.TopBar {
  //   background-color: #fff;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.4em;
  position: relative;

  .Logo {
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      max-height: 70%;
      max-width: 20%;
    }
  }

  .Social {
    width: 50%;
    height: 80%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
    -webkit-font-smoothing: antialiased;

    .icons {
      svg {
        font-size: 2.6em;
        margin-right: 1.5em;
      }
    }

    .hashtag {
      max-width: 40%;
      height: 60%;
      max-height: 60%;
      display: flex;
      align-items: center;
      .BiggerText {
        display: flex;
        align-items: center;
      }
    }
  }
}
