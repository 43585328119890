@import 'assets/scss/variables/colors.scss';
.CustomizePage {
  .main-container-inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;
    height: calc(100vh - 290px);
    margin-top: 180px;
    margin-bottom: 50px;
    overflow-y: scroll;
  }
  .screenOverview {
    width: 576px;
    height: 324px;
    margin-right: 80px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    user-select: none;
    .CustomizeScreen {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      .screen-video-bg {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        object-fit: cover;
        object-position: center;
      }
      & > div > div {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        transform: scale(0.3);
      }
      & .content {
        backface-visibility: hidden !important;
      }
    }
  }

  .confirm-reset-theme {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;

    .confirm-reset-theme__title {
      margin-bottom: 10px;
    }
  }

  .Tabs {
    width: 300px;

    .TabsHeader {
      width: 100%;
      height: 30px;
      border-radius: 30px;
      display: flex;
      flex-flow: row nowrap;
      overflow: hidden;
      margin-bottom: 20px;

      .TabsHeaderItem {
        width: 50%;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: white;
        background-color: $grey-color;
        text-align: center;
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;

        &.active {
          background-color: $brand-color;
        }
      }
    }
    .toggleOptions {
      height: 30px;
      padding: 0 12px;
      display: flex;
      flex-flow: row;
      align-items: center;
      //   justify-content: space-between;
      border-radius: 4px;
      background-color: white;
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.15);
      margin: 15px 0;
      font-size: 12px;
      letter-spacing: 0.5px;
      &:last-of-type {
        margin-top: 0;
        margin-bottom: 25px;
      }
      span {
        padding: 0 12px;
        user-select: none;
      }
    }
    .textarea {
      width: 100%;
      textarea {
        border: 1px solid #dadada;
        // resize: none;
        min-width: 100%;
        max-width: 100%;
        min-height: 60px;
        max-height: 140px;
        width: 100%;
        font-size: 12px;
        font-size: 12px;
        padding: 5px 8px;
      }
    }
  }

  .Collapser {
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.15);
    margin: 15px 0;
    user-select: none;
    .Collapser-header {
      justify-content: flex-start;
      height: 30px;
      line-height: 30px;
      padding: 0 12px;
      span {
        font-size: 12px;
        letter-spacing: 0.5px;
        text-align: left;
        margin-left: 12px;
        flex: 1;
        display: block;
      }
    }
  }
  .Field {
    border-radius: 4px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.15);
    margin: 15px 0;
    user-select: none;
    position: relative;

    .Field-icon {
      position: absolute;
      z-index: 10;
      height: 16px;
      left: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
