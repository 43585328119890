@import 'assets/scss/variables/colors.scss';

/* ------ Option Class ----- /
/  Sizes :                   /
/    - small                 /
/  Colors :                  /
/    - danger                /
/    - success               /
/    - inverted              /
/    - disabled              /
/                            /
/ ------------------------- */

.Button {
  align-self: center;
  text-transform: lowercase;
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.26);
  border: none;

  /* z-index: 50; */
  border-radius: 20px;
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  user-select: none;

  /* default size */
  line-height: 14px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 9px 22px;

  /* default color */
  background-image: linear-gradient(261deg, #be43ec 0%, #6c1dcb 55%, #6c1dcb 100%);
  background-size: 200% 100%;
  background-position: 100%;
  background-repeat: no-repeat;
  color: #fff;

  &:hover {
    background-position: 0;
  }

  &.small {
    font-size: 12px;
    font-weight: 400;
    padding: 5px 20px;
    line-height: 15px;
  }

  &.xsmall {
    font-size: 11px;
    font-weight: 400;
    padding: 4px 10px;
    letter-spacing: 0.6px;
  }

  &.full-width {
    width: 100%;
  }

  &.half-width {
    width: 46%;
    & + .half-width {
      margin-left: 8%;
    }
  }

  &.disabled,
  &:disabled {
    background-image: linear-gradient(#b2b2b2, #b2b2b2);
  }

  &.grey {
    background-image: linear-gradient(261deg, #b2b2b2 0%, #b2b2b2 55%, #b2b2b2 100%);

    // background-image: linear-gradient(#b2b2b2, #b2b2b2);
    // background-color: $danger-color;
    color: #fff;

    &:hover {
      background-position: 0;
    }
  }
  &.danger {
    background-image: none;
    background-color: $danger-color;
    color: #fff;

    &:hover {
      background-color: darken($danger-color, 14%);
    }
  }

  &.success {
    background-image: none;
    background-color: $success-color;
    color: #fff;

    &:hover {
      background-color: darken($success-color, 8%);
    }
  }

  &.info {
    background-image: none;
    background-color: $info-color;
    color: #fff;

    &:hover {
      background-color: darken($info-color, 8%);
    }
  }

  &.inverted {
    background-color: white;
    background-image: none;
    color: #be43ec;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  }
}
