@import 'assets/scss/variables/colors.scss';

.Forms {
  border: 1px solid white;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
  height: 450px;
  width: 450px;
  z-index: 4;
  transition: all ease-in-out 300ms;
  position: relative;
  overflow: hidden;

  form {
    padding: 50px 80px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all ease-in-out 300ms;

    .label {
      width: 100%;
      margin-bottom: 20px;
    }
    .label,
    .forgotPassword {
      transition: none !important;
      opacity: 1;
    }
    .Button {
      transition: background-position 0.3s ease-in-out;
      opacity: 1;
    }

    .Title {
      margin-top: 0;
      margin-bottom: 30px;
      cursor: pointer;
    }
    .Or {
      color: $brand-color;
      font-weight: 700;
      font-size: 15px;
      margin-top: 20px;
      text-align: center;
    }
    span {
      width: 100%;
    }
    > :last-child[type='button'] {
      width: 100%;
      border-radius: 4px !important;
      box-shadow: none !important;
      border: 1px solid #c7c7c7 !important;

      div {
        min-width: 50px;
      }
    }
    .messageErrorText {
      margin-top: 3px;
      font-size: 12px;
      color: $danger-color;
    }
  }
  input {
    border: none;
    width: 100%;
    border-bottom: 1px solid $brand-color;
    padding: 10px 0;
    &::placeholder {
      color: $brand-color;
      letter-spacing: 0.5px;
      font-size: 14px;
    }
  }

  .ButtonLogin {
    width: 100%;
    margin-top: 30px;
  }

  .forgotPassword {
    font-size: 11px;
    align-self: flex-start;
    color: $brand-color;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
  }

  &.collapse {
    border: transparent;
    height: 38px;
    display: block;
    width: 380px;
    z-index: 3;
    form {
      padding: 0 46px;
      justify-content: flex-start;
      .label,
      .Button,
      .forgotPassword {
        opacity: 0;
      }
      .Title.small {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 35px;
        font-size: 16px;
        &::after {
          height: 3px;
          bottom: 8px;
        }
      }
    }
  }
  &.Signup.expand {
    height: 700px;
  }

  &.Login.expand,
  &.ResetPassword.expand {
    height: 500px;
  }

  &.ResetPassword {
    color: $brand-color;
    .ConfirmationEmail {
      text-align: center;
    }
    span {
      font-weight: 600;
      text-align: center;
      margin-top: 10px;
    }
  }

  .kep-login-facebook {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px !important;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 10px;
    text-transform: capitalize;
    width: 100%;
    padding: 3px 5px;
    padding-right: 20px;
    i.fa {
      width: auto;
    }
  }
}
