.DeleteLayoutModal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  .ModalActions {
    margin-top: 30px;
    button + button {
      margin-left: 20px;
    }
  }
}
