@import 'assets/scss/variables/colors.scss';

.PayPage {
  .PlaceOrder {
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 370px;
    margin: 0 auto;
    margin-bottom: 40px;

    .OrderResume {
      text-align: left;
      padding-left: 20px;
      padding-right: 20px;
      letter-spacing: 0.5px;
    }

    .FromTo {
      margin-bottom: 40px;
    }

    .WithCard {
      font-size: 18px;
      margin-bottom: 40px;
    }

    .description {
      font-size: 18px;
    }
    .value {
      margin-left: 10px;
      font-weight: 600;
      color: $brand-color;
      font-size: 18px;
    }

    .CardContainer {
      display: flex;
      justify-content: space-between;

      .Icon {
        color: $brand-color;
      }
    }

    hr {
      margin-bottom: 30px;
    }

    .OrderSummaryLine {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    .TaxListItem {
      min-width: 100px;
      display: flex;
      justify-content: space-between;
      color: #7617ce;
      font-weight: 600;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  .DateLine {
    margin-bottom: 10px;

    > *:first-child {
      width: 55px;
      display: inline-block;
    }

    .Date {
      width: 46%;
      display: inline-block;
      color: $danger-color;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
    }

    .Time {
      color: $danger-color;
      flex: 1;
      font-weight: 600;
      font-size: 18px;
      display: inline-flex;
      width: 35%;
      text-align: right;
      justify-content: flex-end;
    }
  }
}
