.BiggerText {
  width: 100%;
  height: 100%;
  /*display: flex;
  align-items: center;
  justify-content: center;*/
  .child {
    transition: opacity 0.3s ease-in-out;
  }
}
