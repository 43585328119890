@import 'assets/scss/variables/sizes.scss';

.FloatingActions {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ($container-padding-top) / 2;
  z-index: 10;

  > * {
    margin-bottom: 20px;
    margin-right: 30px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .FloatingButton[label='refresh'] svg {
    transition: all 0.3s ease-in-out;

    &.rotate {
      transform: rotate(90deg);
    }
  }
}
.main-container-inner > .FloatingActions {
  padding-top: 0;
}
