@import 'assets/scss/variables/colors.scss';

.PayModal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  .progress-bar {
    width: 100%;
    margin: 40px 0 0;
    border-radius: 20px;
    height: 20px;
    box-shadow: $box-shadow;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: $brand-gradient;
      border-radius: 20px;
      transition: 1s width ease-in-out;
      background-size: 400% 400%;
      animation: gradientBG 10s ease infinite;
    }

    &.INIT:after {
      width: 0;
    }

    &.PROCESSING:after {
      width: 70%;
    }

    &.ERROR:after,
    &.SUCCESS:after {
      width: 100%;
    }

    &.ERROR:after {
      background: $danger-color;
    }
  }
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
