@import 'assets/scss/variables/colors.scss';

.SubmitButton {
  margin: 0 auto;
  position: relative;

  .Button {
    width: auto;
    padding-left: 60px;
    padding-right: 60px;
  }

  .SuccessMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: $info-color;
    position: absolute;
    right: 0;
    left: -6px;
    top: 41px;
  }

  .SubmitButtonContainer {
    display: inline-flex;
    justify-content: center;
    position: relative;
  }

  svg {
    margin-right: 5px;
  }
}
