@import 'assets/scss/variables/colors.scss';

.SetupPage {
  //   width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 60px);
  //   margin-top: 43px;
  padding-bottom: 40px;

  .SetupPageInner {
    position: relative;
    margin: 0 auto;
    // width: 740px;
  }

  .SetupPageHeader {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    letter-spacing: 0.5px;
  }

  .SetupPageContainer {
    display: flex;
    flex-flow: column nowrap;

    .social-connected {
      &.twitter {
        order: 1;
      }
      &.instagram {
        // margin-top: 10px;
        order: 2;
      }
    }

    .SetupPanel {
      &.twitter {
        order: 3;
      }
      &.instagram {
        order: 4;
      }
    }

    .page-legend {
      order: 5;
    }
  }

  .Section {
    display: flex;
    width: 50%;
    flex-direction: column;

    .Input {
      width: 80%;

      input[type='text'] {
        font-size: 16px;
        width: 100%;
      }
    }

    .Title.small {
      margin-left: 0;
      margin-bottom: 0;
    }

    .Upgrade {
      display: flex;
      align-items: center;

      label {
        margin-right: 40px;
        font-size: 20px;
        height: 42px;
        line-height: 42px;
        display: block;
        // text-transform: lowercase;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    // align-self: center;
    span {
      font-weight: 600;
      margin: 0 10px;
      font-size: 20px;
    }
  }
  .Instagram {
    width: 70px;
    height: auto;
    align-self: center;
    margin-bottom: -2px;
  }

  .SetupPanel {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;

    .SetupPanelWrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      width: 100%;
      padding: 60px 40px;

      &.unlinked {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .logo {
          margin-bottom: 25px;
        }

        p {
          margin-top: 25px;
          margin-bottom: 0;
          font-size: 15px;
          font-weight: 600;
          text-align: center;
        }
      }

      &.linked {
        .logo {
          margin-top: 40px;
        }
      }

      .ChipsContainer {
        padding-top: 0;
        padding-bottom: 0;
        .Input {
          max-width: 210px;
          margin: auto;
        }
      }
    }
  }
  .social-connected {
    width: 100%;
    background: $brand-gradient;
    color: #fff;
    height: 74px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 0 30px;
    font-size: 15px;
    margin-bottom: 20px;
    user-select: none;

    img {
      border: 2px solid #fff;
      border-radius: 50%;
      width: 50px;
      height: 50px;
    }
    div {
      display: flex;
      flex-flow: row nowrap;
      & > div {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        margin-left: 10px;
        line-height: 22px;
        font-size: 14px;
      }
    }
  }

  .content-from-my-account {
    // padding-top: 45px;
    // padding-bottom: 45px;
    width: 310px;
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-align: center;

    .Title {
      font-size: 18px;
    }
    .SetupToggle {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      user-select: none;
      > div {
        margin-right: 15px;
        font-weight: 600;
      }
    }
  }

  .page-legend {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    p {
      width: 100%;
      max-width: 600px;
      font-size: 14px;
      line-height: 20px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.Section label {
  font-size: 25px;
  text-transform: lowercase;
}
