@import 'assets/scss/variables/sizes.scss';

.NextStepBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: $sidebar-width;
  text-align: center;
  height: $nextStepBar-height;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  z-index: 3;
  height: 60px;
  @media (max-width: $tablet) {
    padding-left: $sidebar-width-collapsed;
  }

  & ~ .main-container,
  & ~ .main-container-large {
    margin-bottom: $nextStepBar-height;
    height: calc(100vh - #{$header-height} - #{$nextStepBar-height});
    &.LayoutPage,
    &.ThemePage,
    &.CustomizePage {
      height: calc(
        100vh - #{$header-height} - #{$wallbar-height} - #{$nextStepBar-height}
      );
    }
  }
}
