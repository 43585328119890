@import 'assets/scss/variables/sizes.scss';
@import 'assets/scss/variables/zindex.scss';
.MainView {
  position: fixed;
  right: 0;
  top: 60px;
  bottom: 0;
  left: 0;
  z-index: $z-index-mainview;
}
