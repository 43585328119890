@import 'assets/scss/variables/colors.scss';

.DurationPicker {
  display: flex;
  flex-flow: column nowrap;
  & > div {
    width: 100%;
  }

  .arrow {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 35px;
    color: $brand-color;

    svg {
      cursor: pointer;
      //   margin-left: 2px;
    }
  }
  .Timers {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
  }

  .Timer.hide {
    display: none;
  }
  input {
    border: none;
    width: 25px;
    padding: 3px 0;
    height: 20px;
    line-height: 20px;
    font-size: 18px;
    color: $brand-color;
    font-weight: 600;
    text-align: right;
    margin: 0 2px;
    &:focus {
      border-bottom: 1px solid $light-grey-color;
    }
  }

  .spacer {
    font-size: 16px;
    color: $brand-color;
    min-width: 12px;
    margin-right: 2px;
  }
}
