@import 'assets/scss/variables/sizes.scss';
@import 'assets/scss/variables/colors.scss';

.LayoutPage {
  .LayoutThumbnailsList {
    max-width: 721px;
    margin: 0 auto;
    padding-bottom: 50px;

    .Collapser:last-child .CollapserContent {
      justify-content: flex-start;
    }

    .LayoutGroup {
      border-bottom: 2px dotted rgba($brand-color, 0.4);
      padding-bottom: 10px;
      margin-bottom: 30px;

      &:last-of-type {
        border-bottom: none;
      }
      .LayoutGroupTitle {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 30px;
        .Title {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 0;
        }
      }

      .LayoutGroupContent {
        display: grid; /* 1 */
        grid-template-columns: repeat(4, auto); /* 2 */
        grid-column-gap: 10px;
        // grid-gap: 1rem; /* 3 */
        justify-content: flex-start; /* 4 */

        .LayoutThumbnail {
          width: 100%;
          margin-right: 0;
          position: relative;
          &:after {
            content: '';
            flex: auto;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
