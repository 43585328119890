.Checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;

  label {
    padding-left: 10px;
    cursor: pointer;
  }
}
