.DeleteKeywordModal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  .ModalActions {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
