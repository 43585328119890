@import 'assets/scss/variables/colors.scss';

%default-input-style {
  letter-spacing: 0.5px;
  border-bottom: solid 1px $brand-color;

  input[type='password'],
  input[type='email'],
  input[type='text'] {
    width: 100%;
    font-size: 18px;
    line-height: 32px;
    border: none;
    letter-spacing: 0.5px;
    outline: none;
    text-align: center;
    padding: 5px 0;
  }

  .InputError {
    font-size: 12px;
    text-transform: lowercase;
    padding-top: 10px;
    color: $danger-color;
    position: absolute;
    top: 100%;
    user-select: none;
  }

  .CheckMarks {
    position: absolute;
    height: 22px;
    right: 0;
  }

  & input::placeholder {
    text-transform: lowercase;
    color: $brand-color;
  }

  input[type='radio']:checked,
  input[type='radio']:not(:checked) {
    opacity: 0;
    position: absolute;
    height: 100%;
    z-index: 1;
    width: 100%;
  }

  input[type='radio']:checked + label,
  input[type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $brand-color;
  }

  input[type='radio']:checked + label:before,
  input[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid $brand-color;
    border-radius: 100%;
    background: #fff;
  }

  input[type='radio']:checked + label:after,
  input[type='radio']:not(:checked) + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: $brand-color;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  input[type='radio']:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  input[type='radio']:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
}

%simple-input-style {
  position: relative;
  border-bottom: solid 1px $black-color;

  input[type='password'],
  input[type='email'],
  input[type='text'] {
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    letter-spacing: 0.5px;
    font-size: 14px;
    text-transform: initial;
    padding-right: 27px;
  }

  & input::placeholder {
    text-transform: lowercase;
    color: $grey-color;
  }

  .CheckMarks {
    position: absolute;
    height: 18px;
    right: 0;
  }

  .InputError {
    padding-top: 5px;
    user-select: none;
  }
}

.Input {
  @extend %default-input-style;
  position: relative;
  display: flex;
  align-items: center;

  &.no-border,
  &.radio {
    border-bottom: none;
  }

  &.radio {
    display: inline-flex;
    position: relative;
  }

  input[type='checkbox'] {
    width: 100%;
    display: block;
    opacity: 0;
    position: relative;
    z-index: 1;
    height: 20px;

    & + label {
      position: absolute;
      top: 0;
      display: flex;
      cursor: pointer;
    }

    &:not(:checked),
    &:checked {
      & + label > .Checkbox {
        width: 20px;
        height: 20px;
        border-radius: 3px;
        border: 1px solid $grey-color;
        background: white;
        display: inline-block;
        margin-right: 20px;
      }
    }

    & + label > .Icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      color: $info-color;
    }

    &:not(:checked) + label > .Icon {
      opacity: 0;
      transform: scale(0);
    }

    &:checked + label > .Icon {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.simple {
    @extend %simple-input-style;
  }

  &.adder {
    @extend %simple-input-style;

    input[type='text'] {
      font-size: 15px;
      text-align: center;
    }

    & input::placeholder {
      color: $dark-grey-color;
      text-align: center;
    }
  }

  &.WithPrefix {
    display: flex;
    align-items: center;

    .Prefix {
      margin-right: 10px;
    }
  }

  label {
    color: #999;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    top: 10px;
  }

  label.Material {
    color: #999;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    top: 10px;
    transition: 0.2s ease all;
  }

  input:focus ~ label.Material,
  input.notEmpty ~ label.Material {
    top: -5px;
    font-size: 10px;
    color: gray;
  }
}
