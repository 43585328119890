.AccountInfoModal {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  justify-content: center;

  p {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .Input {
    margin-bottom: 20px;
    width: 328px;
  }

  .FormFields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .LeftColumn,
  .RightColumn {
    width: 45%;
  }

  select {
    width: 100%;
    height: 43px;
    border: none;
    background: none;
    appearance: none;
    border-radius: 0;
    border-bottom: 1px solid black;
    margin-bottom: 20px;
    font-size: 14px;

    option {
      letter-spacing: 0.5px;
    }
  }

  .Actions {
    width: 100%;
  }
  .SubmitButton {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    justify-content: center;
  }
}
