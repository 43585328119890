@import 'assets/scss/variables/colors.scss';

.TimezoneSelector-container {
  width: 100%;
  position: relative;

  .TimezoneSelector-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    z-index: 3;
  }
  .TimezoneSelector-input {
    width: 100%;
    color: #333 !important;
    height: 30px;
    border-radius: 4px;
    border: 1px solid $brand-color;
    height: 38px;
    padding-left: 8px;
    position: relative;
    z-index: 4;
  }

  .TimezoneSelector-list {
    position: absolute;
    width: 100%;
    margin-top: 5px;
    border-radius: 4px;
    max-height: 200px;
    background-color: #fff;
    box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.15);
    overflow-y: scroll;
    z-index: 5;
    border: 1px solid $light-grey-color;

    .TimezoneSelector-list-item {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      text-align: left;
      padding-left: 8px;
      background-color: #fff;
      transition: background-color 0.1s ease-in-out;
      cursor: pointer;
      &:hover {
        background-color: $light-grey-color;
      }

      &.selected,
      &.selected:hover {
        background-color: $brand-color;
        color: #fff;
      }
    }
  }
}
