@import 'assets/scss/variables/colors.scss';

.listTheme-item {
  width: 200px;
  height: 150px;
  text-align: center;
  margin: 0 10px 10px;

  user-select: none;
  position: relative;

  transition: all 0.3s ease-in-out;
  opacity: 0;
  & > div {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
  }
  &.loaded {
    opacity: 1;
  }
  .theme-video-bg {
    position: absolute;
    width: 200px;
    height: 113px;
    top: 0;
    left: 0;
    border-radius: 10px;
  }
  .bg-img {
    position: absolute;
    top: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    width: 200px;
    height: 113px;
    border-radius: 10px;
  }

  .ThumbnailPreviewer + .Button {
    margin-left: 5px;
  }

  .Button {
    top: -15px;
    opacity: 0;
  }

  .ThumbnailPreviewer {
    .Button {
      background: #fff;
      color: $brand-color;
      border: 1px solid $brand-color;
    }
  }

  &:hover {
    .Button {
      opacity: 1;
    }
  }
  &.active {
    .bg-img {
      box-shadow: 0 0 0px 3px $info-color, 0 0 15px 0 rgba(0, 0, 0, 0.5);
    }
    .ThumbnailPreviewer + .Button {
      display: none !important;
    }
  }

  p {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    position: absolute;
    bottom: 0;
    color: $brand-color;
  }

  &.createTheme-item {
    width: 175px;
    height: 100px;
    margin-bottom: 30px;
    margin-top: 0;
    border: dotted 1px $brand-color;
    border-radius: 6px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    p {
      margin: 10px;
    }
    .newTheme-icon {
      position: relative;
      width: 30px;
      height: 30px;
      border: solid 2px $brand-color;
      border-radius: 50%;
      transition: transform 0.3s ease-in-out;
      &:before,
      &:after {
        position: absolute;
        content: '';
        background-color: $brand-color;
        border-radius: 1px;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }
      &:before {
        width: 20px;
        height: 2px;
        top: 12px;
        left: 3px;
      }
      &:after {
        width: 2px;
        height: 20px;
        top: 3px;
        left: 12px;
      }
    }
  }

  .round-background-brand {
    background: $brand-color;
  }

  .round-background-brand,
  .round-background-white,
  .round-background-danger {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .round-background-white {
    background: #fff;
  }

  .round-background-danger {
    background: $danger-color;
    border: 2px solid #fff;
    .logoFilm {
      width: 16;
      height: 20;
      color: '#fff';
    }
  }
}
