@import 'assets/scss/variables/sizes.scss';
@import 'assets/scss/variables/zindex.scss';

.Header {
  background-color: white;
  height: $header-height;
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  //   box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #dadada;
  z-index: $z-index-header;
  user-select: none;

  @at-root .Sidebar ~ & {
    padding-left: $sidebar-width;
    @media (max-width: $tablet) {
      padding-left: $sidebar-width-collapsed;
    }
  }

  .TitleHeader {
    width: 100%;
    height: 60px;
    padding-left: 50px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;

    & > div:first-child {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    .Title {
      margin-left: 0;
      font-size: 18px;
      margin-bottom: 2px;
      line-height: 1em;

      &::after {
        bottom: 2px;
        height: 6px;
      }
    }

    .plan {
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
      letter-spacing: 0.5px;
    }
  }

  .WallrusHeaderLogo {
    position: absolute;
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 160px;
    }
  }

  .TitleButtonsContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .BackToDashboardButton {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 210px;
    }
  }
}
