@import 'assets/scss/variables/colors.scss';
.PlaylistScreen {
  box-shadow: $box-shadow;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 30px;
  height: 100px;
  display: flex;
  flex-flow: row nowrap;

  position: relative;
  user-select: none;

  &:last-of-type {
    margin-bottom: 0;
  }

  .preview-screen,
  .screen-duration,
  .screen-move-icon {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
  }

  &.screen-off {
    .preview-screen,
    .screen-duration,
    .screen-move-icon {
      opacity: 0.3;
    }
  }

  .screen-info {
    width: 55%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .actions {
    width: 45%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .screen-name {
    color: $brand-color;
    font-size: 14px;
  }

  .screen-move-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 80px;
    position: relative;
    cursor: pointer;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      //   background-color: red;
      content: '';
    }
  }

  &.gu-mirror {
    background: $brand-color;
    .preview-screen,
    .actions {
      display: none;
    }
    .screen-name {
      position: absolute;
      right: 0;
      left: 0;
      width: 100%;
      text-align: center;
      color: #fff;
    }
  }
}

.PlaylistScreensList {
  display: flex;
  margin-bottom: 50px;

  &.line-mode {
    flex-flow: column nowrap;

    .PlaylistScreen {
      width: 100%;
      padding: 10px 0px 10px 20px;

      .preview-screen {
        width: 140px;
        min-width: 140px;
        height: 80px;
        margin-right: 30px;
        & > div {
          transform: scale(0.073);
        }
      }

      .screen-name {
        padding-right: 10px;
      }

      .screen-move-icon {
        width: 80px;
      }

      .actions {
        justify-content: space-between;
      }
    }
  }

  &.square-mode {
    flex-flow: row wrap;
    justify-content: flex-start;

    .PlaylistScreen {
      width: 23.2%;
      padding: 10px 20px;
      margin: 0 10px 30px;
      height: 180px;
      flex-flow: column nowrap;
      justify-content: space-between;

      &:nth-of-type(4n + 1) {
        margin-left: 0;
      }
      &:nth-of-type(4n + 4) {
        margin-right: 0;
      }

      .screen-name {
        font-size: 14px;
        text-align: center;
      }

      .screen-info {
        flex-flow: column nowrap;
      }

      .preview-screen {
        margin-bottom: 10px;
        width: 155px;
        min-width: 155px;
        height: 87px;
        & > div {
          transform: scale(0.08);
        }
      }

      .actions {
        justify-content: center;
      }

      .screen-move-icon {
        width: 100%;
      }

      .screen-info,
      .actions {
        width: 100%;
      }

      .screen-duration,
      .Toggle {
        display: none;
      }
    }
  }

  /* FIX
  * TextPostScreen .CardsContainer contains
  * 2 cards that overflows and when dragging this line,
  * the component breaks
  */

  .TextPostScreen {
    .CardsContainer {
      height: 100% !important;
      max-height: 100% !important;

      .Card:nth-of-type(3),
      .Card:nth-of-type(4) {
        display: none !important;
      }
    }
  }
}
