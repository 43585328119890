.TextPicScreen {
  text-align: center;
  display: flex;
  flex-flow: column;
  position: relative;
  display: block;
  height: 1080px;
  width: 1920px;
  transform-origin: 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: transparent;

  .TopBar {
    height: 190px;
  }
  .CardsContainer {
    overflow-x: hidden;
    width: 100%;
    height: 890px;
    padding: 50px 0;
    position: absolute;
    left: 0;
    top: 190px;
    display: block;
    .Card {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: row wrap;
      will-change: transform, opacity;
      height: 790px;
      &.img {
        padding: 30px;
        .media {
          width: 100%;
          height: 55%;
          position: relative;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          video {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
          }
        }
        .content {
          width: 100%;
          height: 45%;
          display: flex;
          flex-flow: row wrap;
          padding-top: 10px;
          .source {
            height: 34px;
            width: 30px;
            order: 1;
            font-size: 26px;
            padding-top: 2px;
          }
          .name {
            width: calc(100% - 30px);
            order: 2;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding-top: 5px;
            padding-left: 20px;
            margin-bottom: 10px;
            p:last-child {
              margin-left: 10px;
            }
          }
          .BiggerText {
            width: 100%;
            order: 3;
            text-align: left;
            height: calc(100% - 50px);
          }
        }
        &.bis {
          .media {
            height: 75%;
          }
          .content {
            height: 25%;
          }
        }
      }
      &.txt {
        .media {
          display: none;
        }
        .content {
          height: 100%;
          width: 100%;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: space-around;
          padding: 0 30px;
          .name {
            height: 30%;
            width: 100%;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            p {
              &:first-child {
                font-size: 26px;
              }
              &:last-child {
                font-size: 18px;
              }
            }
          }
          .BiggerText {
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 50%;
          }
          .source {
            height: 25%;
            width: 100%;
            font-size: 30px;
            padding-top: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      p {
        width: 100%;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .name p {
      margin: 0;
      width: auto;
      &:first-child {
        font-weight: 600;
        font-size: 16px;
      }
      &:last-child {
        opacity: 0.51;
        font-size: 13px;
      }
    }
  }
}
