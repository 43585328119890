@import 'assets/scss/variables/colors.scss';

.ThemePage {
  &.main-container-large {
    .main-container-inner {
      max-width: 1200px;
    }
  }
  .main-container-inner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;

    .listTheme {
      width: 100%;
      margin-bottom: 100px;
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: flex-start;

      @media screen and (max-width: 1430px) {
        max-width: 660px;
      }

      @media screen and (max-width: 1019px) {
        max-width: 440px;
      }

      @media screen and (max-width: 820px) {
        max-width: 220px;
      }
    }
  }
}
