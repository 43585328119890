@import 'assets/scss/variables/colors.scss';

.FreeModerationModal {
  user-select: none;
  .Block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    span {
      color: $brand-color;
      max-width: 300px;
      line-height: 25px;
      letter-spacing: 0.5px;
      font-size: 21px;
    }

    img {
      height: 90px;
    }
  }

  .ModalFooter {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
