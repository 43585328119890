@import 'assets/scss/variables/colors.scss';
$title-background-color: #e6e6e6;

.Title {
  position: relative;
  color: $brand-color;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.38;
  letter-spacing: 0.5px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
  z-index: 2;
  user-select: none;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 10px;
    bottom: 5px;
    left: 0;
    background-color: $title-background-color;
    z-index: -1;
  }

  &.inverted {
    color: white;

    &::after {
      background-color: $brand-color;
    }
  }

  &.no-bg {
    &::after {
      display: none;
    }
  }

  &.medium {
    font-size: 28px;

    &::after {
      height: 5px;
    }
  }

  &.small {
    font-size: 20px;

    &::after {
      height: 5px;
    }
  }
}
