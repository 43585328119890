@import 'assets/scss/variables/colors.scss';

.Badge {
  position: absolute;
  z-index: 15;
  box-shadow: $box-shadow;
  z-index: 2;

  &.text {
    color: $brand-color;
    background: #fff;
    border: 2px solid $brand-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    border-radius: 15px;
    height: 23px;
    padding: 0 8px;
  }

  &.image {
    width: 29px;
    height: 29px;
    padding: 0;
    border: none;
    border-radius: 50%;
  }

  &.top-right {
    top: -8px;
    right: -8px;
  }
  &.top-left {
    top: -8px;
    left: -8px;
  }
  &.bottom-right {
    bottom: -8px;
    right: -8px;
  }
  &.bottom-left {
    bottom: -8px;
    left: -8px;
  }
}
