@import 'assets/scss/variables/colors.scss';

.Visualizer {
  position: absolute;
  width: 1920px;
  height: 1080px;
  transform-origin: 0 0;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  user-select: none;
  .screen-video-bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
    object-position: center;
  }
  & > div {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}
