@import 'assets/scss/variables/colors.scss';

.ZoomModerationModal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  .ModalActions {
    margin-top: 30px;
    button + button {
      margin-left: 20px;
    }
  }
  .Slider {
    width: 100%;
    text-align: center;
    position: relative;
    .videoBackground {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
    }

    .layoutIndex {
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      &.loaded {
        opacity: 1;
      }

      .themeImage {
        width: 100%;
        position: relative;
      }
      .layoutName {
        color: $brand-color;
        margin-top: 15px;
      }
    }

    .MediaPreview {
      width: 100%;
      max-height: 55vh;
      object-fit: contain;
    }
  }
}
