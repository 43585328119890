@import 'assets/scss/variables/sizes.scss';
@import 'assets/scss/variables/colors.scss';
@import 'assets/scss/variables/zindex.scss';

.Sidebar {
  height: 100vh;
  padding: 0;
  width: $sidebar-width;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: $sidebar-background-color;
  z-index: $z-index-sidebar;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .SidebarPanel {
    background-color: inherit;
    color: #fff;
    text-transform: lowercase;
    overflow: hidden;
    // letter-spacing: 0.5px;
    position: relative;
    cursor: pointer;
    margin: 0;
    backface-visibility: hidden;
    background-color: $sidebar-background-color;

    .ItemName {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      backface-visibility: hidden;
    }

    .SidebarPanel-Header {
      height: $sidebar-item-header-height;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 20px;
      padding-right: 20px;
      backface-visibility: hidden;
      transition: background-color 0.2s ease-in-out;

      .SidebarPanel-position,
      .SidebarPanel-error {
        display: flex;
        width: 23px;
        height: 23px;
        min-width: 23px;
        min-height: 23px;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 12px;
        span {
          color: #fff;
          font-size: 11px;
        }
        svg {
          position: absolute;
        }
      }

      .SidebarPanel-error svg {
        color: $danger-color;
      }

      .SidebarPanel-name {
        font-size: 15px;
        letter-spacing: 0.5px;
        transition: color 0.2s ease-in-out;

        & + svg {
          margin-left: auto;
        }
      }

      &:hover {
        .SidebarPanel-name {
          &,
          & + svg {
            color: $info-color !important;
          }
        }
      }
      @media (max-width: $tablet) {
        display: none;
      }
    }

    .SidebarPanelChildren {
      overflow: hidden;
      @media (max-width: $tablet) {
        height: 100% !important;
        opacity: 1 !important;
        transform: translate3d(0px, 0px, 0px) !important;
      }
    }

    &.selected {
      color: $info-color;
      background-color: $sidebar-inner-bg;

      .SidebarPanel-Header {
        .SidebarPanel-position span {
          color: $info-color;
        }

        .SidebarPanel-name + svg {
          color: transparent !important;
        }
      }
      @media (max-width: $tablet) {
        background-color: $sidebar-background-color;
      }
    }

    &.no-collapse {
      .SidebarPanel-Header {
        svg.fa-chevron-down {
          display: none;
        }
      }
      .SidebarPanelChildren {
        transition: none;
        height: 0 !important;
      }
    }

    .SidebarItem {
      font-weight: 400;
      height: $sidebar-item-height;
      //   background-color: $sidebar-inner-bg;
      display: flex;
      align-items: center;
      cursor: pointer;
      backface-visibility: hidden;

      & > div {
        width: 100%;
        height: 45px;
        padding-left: 40px;
        display: flex;
        align-items: center;
        text-decoration: none;

        .Icon svg,
        .Icon {
          width: 25px;
          color: rgba(#fff, 0.4);
          fill: currentColor;
          height: 20px;
        }

        .ItemName {
          color: #fff;
          display: inline-block;
          padding-left: 12px;
          position: relative;
          width: 100%;
          font-weight: 400;

          .ItemName-Content {
            font-size: 12px;
            display: flex;
            align-items: center;
            height: 100%;
            letter-spacing: 0.5px;
            font-weight: 500;
          }
          @media (max-width: $tablet) {
            display: none;
          }
        }

        .SidebarItem-Message {
          color: $danger-color;
          font-size: 12px;
          position: absolute;
          margin-top: 3px;
          font-weight: 400;
        }

        .Icon svg,
        .ItemName {
          transition: all 0.2s ease-in-out;
        }
        @media (max-width: $tablet) {
          padding: 0;
          display: flex;
          justify-content: center;
        }
      }

      &:hover,
      & .selected {
        .Icon svg,
        .ItemName {
          color: $info-color;
        }
      }
      @media (max-width: $tablet) {
        margin-top: 8px;
        width: 100%;
      }
    }
    &.isSubuser {
      margin-top: 10px;
      background: $sidebar-background-color;
    }
  }
  & ~ .MainView {
    left: $sidebar-width;
    width: auto;
  }

  .Sidebar-back-dashboard {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    line-height: 45px;
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #f3f3f3;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $brand-gradient;
    text-decoration: none;

    &:hover span {
      padding-left: 4px;
    }

    span {
      transition: padding 0.3s ease-in-out;
      font-weight: 500;
      @media (max-width: $tablet) {
        display: none;
      }
    }
  }
  @media (max-width: $tablet) {
    width: $sidebar-width-collapsed;

    &.open {
      width: $sidebar-width;
    }

    & ~ .MainView {
      left: $sidebar-width-collapsed;
    }
  }
}
.Sidebar-Wallrus-logo {
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $brand-gradient;

  a {
    text-decoration: none;
    color: white;
  }

  img {
    width: 160px;
    @media (max-width: $tablet) {
      display: none;
    }
  }
  .WallrusLogoSolo {
    display: none;
    @media (max-width: $tablet) {
      display: block;
      width: 40px;
    }
  }
}
