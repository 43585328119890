@import 'assets/scss/variables/colors.scss';

.SubUsersModal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }
  .ChipsContainer-list {
    margin-bottom: 40px;
    .AreYouSureSection {
      margin: 0 10px;

      .Button {
        font-size: 15px;
        margin: 0 6px;
      }
      .AreYouSureText {
        font-size: 14px;
      }
    }
  }

  form {
    min-width: 688px;

    .ChipsContainer-list {
      .AreYousureSection {
        .AreYouSureText {
          font-size: 12px;
        }
        button {
          margin: 0 10px;
        }
      }
    }
  }

  .SubUsersInputsContainer {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    margin-top: 10px;

    .Input {
      width: 205px;
      margin: 0 12px;
      input {
        padding-right: 19px;
      }
      &.Username {
        label {
          margin-left: 27px;
        }
        .Prefix {
          font-size: 14px;
        }
      }
    }
  }

  tr {
    border-bottom: 1px dotted $brand-color;
    padding: 20px 0;
  }

  .SubmitButton {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .SubUsersWallsContainer {
    border-top: 1px solid #dadada;
    padding-top: 60px;
    margin-top: 40px;
    .ScrollDiv {
      margin-top: 10px;
      max-height: 28vh;
      overflow: auto;
    }
    table {
      width: 100%;
    }
    .WallRow {
      border-spacing: 5rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px dotted $brand-color;
      padding: 20px 0;
      .WallName {
        width: 20%;
      }
    }
    .SubWallsRows {
      display: flex;
      width: 80%;
      justify-content: space-around;
    }

    .SubUsersContainer {
      width: 100%;
      justify-content: space-around;
      display: flex;
      .SubUserHeader {
        min-width: 20%;
      }
      .SubUserNames {
        width: 80%;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
