@import 'normalize.css';
@import 'assets/scss/variables/colors.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: $black-color;
}

* {
  box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'Montserrat', sans-serif;
}

input {
  font-size: 14px;
}
