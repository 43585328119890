//  The basic layout for the app looks like
// | ----------------------------------------------
// |               |     Header z-index: 100      |
// |               |-------------------------------
// |               |                              |
// |    Sidebar    |          Main View           |
// |  z-index:200  |          z-index:1           |
// |               |                              |
// |               |                              |
// |               |                              |
// |               |                              |
// |               |                              |
// |               |                              |
// | ----------------------------------------------
// Every other com are added in between .ie
// Buttons should be between z-index 1 and 200, not over.
$z-index-mainview: 1;
$z-index-create-wall-form: 2;
$z-index-header: 300;
$z-index-sidebar: 1001;
$z-index-select-plan-list: 100;
$z-index-notification: 1000;
$z-index-debug: 2000;
$z-index-modal: 150;
