@import 'assets/scss/variables/colors.scss';

.Toggle {
  & *,
  & *::before,
  & *::after {
    transition: 0.25s ease-in-out;
    user-select: none;
  }

  .checkbox-label {
    display: block;
    position: relative;
    font-size: 12px;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    cursor: pointer;

    .TogglingBall {
      display: block;
      position: absolute;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      top: 1px;
      left: 1px;
      background: $grey-color;
    }
  }

  .switch-toggle {
    position: absolute;
    top: -5000px;
    height: 0;
    width: 0;
    opacity: 0;
    border: none;
    outline: none;

    & + label::before {
      background-color: $brand-color;
    }

    & + .checkbox-label::before {
      background-color: $grey-color;
    }

    & + .checkbox-label {
      box-shadow: inset 0 0 0 0 #fff, 0 0 0 2px $grey-color;
    }

    &:checked + .checkbox-label {
      box-shadow: inset 0 0 0 0 #fff, 0 0 0 2px $brand-color;

      .TogglingBall {
        left: calc(100% - 19px);
        background-color: $brand-color;
      }
    }

    &:checked + .checkbox-label::after {
      color: $brand-color;
    }
  }

  & + span.label {
    cursor: pointer;
    user-select: none;
    &.unchecked {
      opacity: 0.7;
    }
  }

  &.Toggle-small {
    .checkbox-label {
      width: 28px;
      height: 14px;

      .TogglingBall {
        height: 12px;
        width: 12px;
      }
    }

    .switch-toggle:checked + .checkbox-label .TogglingBall {
      left: calc(100% - 13px);
    }
  }
}
