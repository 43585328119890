.ImageScreen {
  height: 1080px;
  width: 1920px;
  transform-origin: 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
