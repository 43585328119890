@import 'assets/scss/variables/colors.scss';

.Uploader {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: relative;

  img,
  video,
  .Uploader-drop {
    width: 100%;
    object-fit: contain;
    height: auto;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    & + .Button {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: 24px;
      width: 100px;
    }
  }
  img {
    background-color: #f0f0f0;
  }
  .Uploader-drop {
    .Uploader-drop-bg {
      width: 80%;
      height: 60px;
      background: #f9f9f9;
      border: 1px dashed #cfcfcf;
      padding: 10px;
      font-size: 11px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      strong {
        color: $brand-color;
      }

      img {
        width: 24px;
        height: 30px;
        margin-right: 10px;
      }
      &.center {
        justify-content: center;
      }
    }
    input {
      opacity: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      position: absolute;
    }
  }

  legend {
    font-size: 13px;
    position: absolute;
    bottom: 0;
  }
}

.uploader-colorpicker .Uploader .Uploader-drop {
  height: 80px;
  padding-bottom: 12px;
}

.LayoutThumbnailImage .Uploader {
  opacity: 0;
}