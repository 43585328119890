@import 'assets/scss/variables/colors.scss';

.PayPage .PaymentMethod {
  margin-bottom: 30px;

  .PayPageBox.Payment {
    position: relative;
    z-index: 1;
    padding: 30px 60px;
    max-width: 370px;
    margin: 0 auto;

    .Input {
      margin-bottom: 30px;
    }

    .MonthAndCVC {
      display: flex;
      justify-content: space-between;

      > * {
        width: 45%;
      }
    }
  }

  .PaymentBillingInfo {
    z-index: 0;
    position: relative;
    bottom: 40px;
    width: 100%;
    margin: 0 auto;
    top: -13px;
    // max-height: 110px;
    // overflow: hidden;
    // transition: all 0.3s ease-in-out;
    padding-bottom: 40px;

    .Collapser {
      margin-bottom: 0;
      .Collapser-header {
        justify-content: center;
      }
    }

    .PaymentBillingHeader {
      &.focusable {
        cursor: pointer;
      }

      /* .OptionalCaret {
        transition: all 0.3s ease-in-out;

        .Icon svg {
          transition: all 0.3s ease-in-out;
        }

        &.rotate {
          .Icon svg {
            transform: rotate(180deg);
          }
        }
      } */
    }

    .Title {
      //   margin-bottom: 30px;
      margin-bottom: 0;
    }

    &.Opened {
      max-height: 1000px;
    }

    .SmallText {
      letter-spacing: 0.5px;
      font-size: 10px;
      margin-bottom: 10px;
    }

    .FieldContainer {
      padding: 0 20px;

      .Input,
      select {
        font-size: 14px;
        margin-bottom: 37px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      select {
        width: 100%;
        height: 43px;
        border: none;
        background: none;
        appearance: none;
        border-radius: 0;
        border-bottom: 1px solid black;

        option:first-child {
          color: light-grey;
        }

        option {
          letter-spacing: 0.5px;
        }
      }
    }
  }

  .Spacer {
    height: 30px;
  }
}
