@import 'assets/scss/variables/zindex.scss';

.WallForm {
  width: 100%;
  display: flex;
  align-items: center;
  .CreateWallForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    z-index: $z-index-create-wall-form;

    .center {
      margin: 0 auto;
      margin-left: 0;
      margin-right: 0;
    }

    .ErrorCheckmark,
    .SuccessCheckmark {
      right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .Button {
      margin-top: 30px;
    }
  }
}
