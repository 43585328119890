// The performance implication are based on:
// https://developers.google.com/web/fundamentals/design-and-ux/animations/animations-and-performance
// Based on https://codepen.io/shshaw/pen/gsFch

@import 'assets/scss/variables/colors.scss';

.AccountMenu {
  right: 0;
  padding-left: 0;
  padding-right: 50px;
  list-style-type: none;
  text-align: left;
  width: 30%;
  background-color: #fff;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;

  .username {
    padding-right: 30px;
    height: 58px;
    line-height: 58px;
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    font-size: 15px;
    letter-spacing: 0.5px;
  }

  .username,
  span {
    z-index: 1;
    position: relative;
  }

  svg {
    transition: transform 0.3s ease-in-out;
    position: absolute;
    top: 0;
    right: 50px;
    bottom: 0;
    margin: auto;

    &.hide {
      opacity: 0;
      transform: rotate(90deg);
    }
  }

  &.show {
    .AccountMenu-submenu {
      visibility: visible;
      opacity: 1;
      transform: translate(0%, 0%);
      transition-delay: 0s, 0s, 0.3s;
    }
  }

  .AccountMenu-submenu {
    visibility: hidden;
    opacity: 0;
    transform: translate(0%, -50%);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: inherit;
    position: fixed;
    top: 60px;
    right: 10px;
    width: 300px;
    z-index: -1;
    background: #fff;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s;
    border: solid 1px #dadada;
    border-top-color: transparent;
    border-top: 1px solid #efefef;

    .AccountMenu-item-container {
      padding: 10px 0;
      width: 100%;
    }

    .AccountMenu-item,
    .ModalButtonWrapper,
    .logoutButton {
      font-size: 12px;
      line-height: 34px;
      letter-spacing: 0.5px;
      text-align: center;
      width: 100%;
      height: 34px;
      cursor: pointer;
      text-decoration: none;

      &:visited,
      &:active,
      &:link {
        text-decoration: none;
        color: $black-color;
      }

      &:hover {
        background-color: #e8e8e8;
      }
    }

    .logoutButton {
      background-color: $black-color;
      color: white;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: lighten($black-color, 15%);
      }
    }

    .admin {
      display: flex;
      flex-flow: column;
      height: 68px;
      border-top: solid 1px #dadada;
      border-bottom: solid 1px #dadada;
      width: 100%;
    }
  }

  [data-icon='angle-right'] {
    transition: all 0.3s ease-in-out;
  }
}
