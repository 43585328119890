.TextPostScreen {
  text-align: center;
  display: flex;
  flex-flow: row-reverse nowrap;
  overflow: hidden;
  height: 1080px;
  width: 1920px;
  transform-origin: 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: transparent;

  .CardsContainer {
    width: 56%;
    height: 200%;
    overflow-x: hidden;
    padding-top: 40px;
    padding-left: 50px;

    .Card {
      position: relative;
      width: 100%;
      will-change: transform, opacity, height;
      overflow: hidden;

      .content {
        width: 100%;
        height: 100%;
        padding: 20px 40px 0;
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: space-between;

        .BiggerText {
          text-align: left;
          margin-bottom: 20px;
          overflow: hidden;
        }

        .bottomCards {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          border-top: solid 1px #979797;
          height: 130px;
          padding: 20px 0;
          align-items: center;

          .profil {
            border-radius: 50%;
            width: 83px;
            height: 83px;
            margin-right: 25px;
          }

          .name {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: center;

            p {
              font-size: 30px;
              margin: 5px 0;

              &:last-child {
                font-size: 26px;
                opacity: 0.51;
              }
            }
          }

          .fa-twitter {
            margin-left: auto;
            width: 63px;
            height: 50px;
          }
        }

        &.size-0 {
          .BiggerText {
            height: 63%;
          }
        }

        &.size-1 {
          .BiggerText {
            height: 69%;
          }
        }
      }
    }
  }

  .rightColumn {
    width: 44%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .Logo {
      width: 70%;
      height: 40%;
      justify-content: center;

      img {
        object-fit: cover;
        max-height: 100%;
        max-width: 100%;
      }
    }

    .Social {
      height: auto;
      flex-flow: column;
      width: 80%;

      .icons svg {
        margin: 0 20px;
        width: 72px;
        height: 60px;
      }

      .hashtag {
        margin-top: 20px;
        max-width: 100%;
        max-height: none;
        width: 100%;
      }
    }
  }
}
