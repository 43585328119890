.SupportModal {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  text-align: center;
  //   width: 570px;

  .Title {
    margin-bottom: 25px;
  }

  .Title.small {
    margin-bottom: 0;
  }

  .Block {
    margin-bottom: 25px;
  }
  
  .Block p {
    margin: 0;
  }

  a {
    display: block;
    color: black;
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #7617ce;
    }
  }
}
