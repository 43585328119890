@import 'assets/scss/variables/sizes.scss';
@import 'assets/scss/variables/zindex.scss';

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  .ReactModal__Content {
    max-height: 75vh;
    &.ReactModal__Content--after-open {
      overflow: hidden !important;
    }

    &.zoom-modal::before {
      content: '';
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      padding-top: 26.4vw;
    }
    &.zoom-modal::after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  @media (max-width: 1200px) {
    left: 80px !important;
  }
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.close-modal-button {
  position: absolute;
  top: 15px;
  right: 15px;

  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

.ModalPortal {
  .ModalComponent > *:first-child {
    text-align: center;
  }

  .modal-container {
    display: flex;
    flex-direction: column;

    &.small {
      width: auto;
      height: auto;
      padding: 40px !important;
    }

    &.square {
      width: 350px;
      height: 350px;
    }

    &.rectangle {
      width: 530px;
      min-height: 230px;
      justify-content: space-between;
    }

    &.large {
      width: 570px;
      justify-content: space-between;
    }

    &.xlarge {
      width: 830px;
      justify-content: space-between;
    }
  }

  .Title {
    margin-bottom: 20px;
  }

  .ModalDescription {
    text-align: center;
  }

  .ModalActions {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    .Button {
      margin-top: 30px;
    }
  }

  .Modal-container {
    position: relative;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    z-index: $z-index-modal;

    .btn-close {
      border: none;
      background-color: transparent;
      width: 20px;
      height: 20px;
      padding: 0;
      position: absolute;
      right: 23px;
      top: 23px;
    }

    &_after-open {
      animation: bounceIn 0.4s;
    }

    &_before-close {
      transform: scale(7);
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    .modal-container {
      padding: 50px;

      .buttons {
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-around;

        .Button {
          margin: 0;
        }
      }
    }
  }

  .Modal-backdrop {
    position: absolute;
    top: 60px;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(35, 35, 35, 0.7);
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    z-index: $z-index-modal;
    display: flex;
    align-items: center;
    justify-content: center;

    &_after-open {
      opacity: 1;
    }

    &_before-close {
      opacity: 0;
    }
  }
}

.ModalsContainer.isOpen {
  .transparent-backdrop {
    position: fixed;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: $z-index-modal - 1;
  }
}

.Sidebar ~ .ModalsContainer.isOpen .transparent-backdrop {
  top: 60px;
  left: 240px;
}

.ModalsContainer.full {
  .Modal-backdrop {
    top: 0;
    left: 0 !important;
  }
}

.ModalsContainer .Modal-backdrop {
  left: 0px;
  @media (max-width: $tablet) {
    & {
      left: 60px;
    }
  }
}

.HelpModal {
  .SectionTitle {
    display: flex;
    justify-content: center;
  }
  .SectionContent {
    margin-bottom: 40px;
    letter-spacing: 0.5px;
    font-size: 14px;
  }
}

@keyframes bounceIn {
  from,
  50%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  80% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
