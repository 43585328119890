.NavButton {
  .Icon {
    color: black;
    height: 100%;
    width: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;

    svg {
      width: inherit;
      height: inherit;
    }
  }
  &:before,
  &:after {
    display: none;
  }
}
