@import 'assets/scss/variables/colors.scss';
@import 'assets/scss/variables/sizes.scss';

.Wallbar-container {
  .Wallbar {
    position: fixed;
    right: 0;
    top: 60px;
    left: 0;
    min-height: $wallbar-height;
    background: #fff;
    z-index: 3;
    box-shadow: $box-shadow;
    user-select: none;

    .Sidebar ~ & {
      left: 240px;
      @media (max-width: $tablet) {
        & {
          left: 80px;
        }
      }
    }

    .Wallbar-inner {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 2px 0;
      position: relative;
      z-index: 10;
      background: #fff;
      min-height: 174px;

      .carousel-left,
      .carousel-right {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .layouts-container {
        height: 100%;
        max-width: calc(100% - 200px);
        display: block;
        overflow: hidden;
        position: relative;

        .Wallbar-Layouts {
          min-height: 100px;
          display: flex;
          align-items: flex-start;
          margin: auto;
          .layout > div {
            transform: scale(0.084);
          }
        }

        .WallbarItem {
          background: #f0f0f0;
          width: 190px;
          min-width: 190px;
          height: 170px;
          border: 2px solid #fff;

          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: opacity 0.3s ease-in-out;
          will-change: transform, opacity;

          .trash {
            opacity: 0;
            z-index: 10000;
            transform: none !important;
            position: absolute;
            top: 0;
            left: 0;
            width: 34px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $danger-color;
            box-shadow: $box-shadow;
            cursor: pointer;
            transition: opacity 0.3s ease-in-out;
            transition-delay: 0s;
            svg {
              transition: all 0.3s ease-in-out;
              box-shadow: none;
            }
          }

          &:hover {
            .trash {
              opacity: 1;
              &:hover svg {
                transform: scale(1.2);
                box-shadow: $box-shadow;
              }
            }
          }
          .layout {
            width: 162px;
            min-width: 162px;
            height: 91px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            position: relative;
            overflow: hidden;
            transition: opacity 0.3s ease-in-out;

            .screen-video-bg {
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 0;
              object-fit: cover;
              object-position: center;
            }
            & > div {
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
            }

            & .content {
              backface-visibility: hidden !important;
            }
          }

          span {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.5px;
            margin-top: 10px;
            user-select: none;
            transition: transform 0.1s ease-in-out;
          }
        }

        &.display-selected {
          .WallbarItem {
            &:not(.selected) {
              .layout {
                opacity: 0.5;
                width: 150px;
                min-width: 150px;
                height: 84px;
                & > div {
                  transform: scale(0.078);
                }
              }
              span {
                opacity: 0.5;
              }
            }

            &:hover {
              opacity: 1;
              .layout,
              span {
                opacity: 1;
              }
            }

            &.selected {
              span {
                transform: translate3d(0, 7px, 0);
              }
            }
          }
        }
      }
    }

    &.collapsed {
      .Wallbar-inner {
        justify-content: center;

        .layouts-container {
          position: relative;
          width: 100%;
          justify-content: center;
        }
        .left-gradient {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 100px;
          bottom: 0;
          width: 30px;
          pointer-events: none;
          z-index: 100;
          background: linear-gradient(to left, rgba(255, 255, 255, 0.001) 0%, #fff 100%);
        }
        .gradient {
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 100px;
          bottom: 0;
          width: 30px;
          pointer-events: none;
          background: linear-gradient(to right, rgba(255, 255, 255, 0.001) 0%, #fff 100%);
        }
      }
    }

    &.expanded {
      .Wallbar-inner {
        align-items: center;

        .layouts-container {
          .Wallbar-Layouts {
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-left: 10px;
          }
        }
      }
    }

    .Wallbar-expander {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 20px;
      margin: 20px auto 0;
      cursor: pointer;
      z-index: 0;

      .Icon {
        position: relative;
        height: 40px;
        z-index: 2;

        svg {
          position: relative;
          z-index: 3;
          top: 14px;
        }
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 30px;
          left: 0;
          right: 0;
          border-radius: 50%;
          margin-left: -3px;
          border: 1em solid black;
          border-color: transparent transparent #fff #fff;
          transform-origin: 0 0;
          transform: rotate(-45deg);
          box-shadow: $box-shadow;
        }
      }
    }

    .Icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      color: $brand-color;

      &:hover {
        color: $brand-color;
      }
    }

    .Wallbar-dropzone {
      height: 174px;
      width: 100%;
      min-width: 500px;
      background-size: 160px;

      top: 0;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: #fff;
      z-index: 10;

      & + .Wallbar-Layouts {
        opacity: 0;
      }

      h1 {
        margin: auto;
        color: $brand-color;
        user-select: none;
      }
    }

    .gu-transit {
      display: none;
    }
  }

  .Wallbar-backdrop {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    right: 0;
    bottom: 0;
    top: 60px;
    width: 100%;
    z-index: 1;
  }

  @at-root .Sidebar ~ & {
    padding-left: $sidebar-width;
    @media (max-width: $tablet) {
      padding-left: $sidebar-width-collapsed;
    }
  }
}
