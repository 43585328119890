@import 'assets/scss/variables/colors.scss';
.ContentStatusModal {
  text-align: center;

  .ContentStatusDescription {
    margin-bottom: 30px;
  }

  .ContentStatusContent {
    display: flex;
    flex-flow: column;
    align-items: center;
    .Icon {
      display: flex;
      width: 34px;
      height: 34px;
      position: relative;
      border: 2px solid $brand-color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      margin-right: 10px;
    }
    .ContentStatusMessage {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 400px;
      margin: 0 auto;
      font-size: 15px;
      > div {
        overflow: scroll;
      }

      &.valid {
        color: $info-color;
      }

      &.danger {
        color: $danger-color;
      }

      &.warning {
        color: $warning-color;
      }

      .StatusMessage {
        .ScreenName {
          font-weight: 600;
        }
        .ScreenMessage {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }
      }
    }
  }
}
