.ColorPicker {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  .circle-picker {
    margin-bottom: 0px !important;
    justify-content: center;
    & > span > div > span > div[title='#ffffff'] {
      border: 1px solid #dadada;
    }
  }
  .more-less {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .Button {
      margin-top: 6px;
    }
  }

  svg {
    align-self: flex-start;
    position: relative;
    z-index: 1000;
    margin-left: 5px;
    margin-right: 14px;
  }

  .chrome-picker {
    box-shadow: none !important;
  }
}

.transparent-circle {
  width: 12px;
  height: 12px;
  min-width: 12px;
  max-width: 12px;
  position: relative;
  transform: rotate(-45deg);
  &.picker {
    margin-left: -10px;
    margin-right: 8px;
    width: 16px;
    height: 16px;
    min-width: 16px;
    max-width: 16px;
    cursor: pointer;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    max-width: 16px;
    max-height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid #bebebe;
  }
  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #ef3c3c;
    display: block;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
  }
}
