@import 'assets/scss/variables/colors.scss';
.PlaylistModal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  //   height: 100px;

  span {
    color: $brand-color;
  }

  .select-container {
    width: 30%;
  }

  .row-center {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .select-container {
      margin-left: 20px;
    }
  }
}
