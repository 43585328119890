@import 'assets/scss/variables/colors.scss';

.SelectPlanItem {
  width: 25%;
  min-height: 372px;
  max-width: 250px;
  margin: 0 15px;
  padding: 20px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  transition: all 0.2s ease-in-out;
  height: 100%;
  position: relative;
  user-select: none;
  border: 1px solid #f2f2f2;
  cursor: pointer;
  
  @media (max-height: 800px) {
    padding-top: 10px;
  }

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &.agency {
    cursor: initial;
  }

  .CheckMarkContainer {
    height: 20px;
  }

  &.selected,
  &:hover:not(.agency) {
    transform: scale(1.1);
    border: solid 1px $info-color;
  }

  &.disabled {
    &:focus,
    &:focus-within,
    &:hover {
      transform: none;
      border: none;
    }

    box-shadow: none;
    background-color: darken(white, 20%);

    .Title::after {
      background-color: darken(#f3f3f3, 20%);
    }

    ul li {
      &:nth-child(even) {
        background-color: darken(white, 25%);
      }
    }
  }

  & .Title {
    margin-bottom: 10px;
  }

  .Button {
    margin-bottom: 0;
  }

  ul li {
    &:nth-child(even) {
      background-color: #f2f2f2;
    }

    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.5px;
  }

  .price {
    font-weight: 600;
    font-size: 25px;
    letter-spacing: 0.5px;

    &.barred {
      position: relative;
      font-size: 18px;
    }

    &.brand-color {
      color: $brand-color;
      font-size: 30px;
    }
  }

  .from {
    font-size: 10px;
    letter-spacing: 0.5px;
  }

  .try-free {
    display: block;
    margin: 10px;
    color: $brand-color;
    font-size: 13px;
  }
  .SelectPlanItemDisabledMessage {
    bottom: -50px;
    text-align: left;
    font-size: 12px;
    text-transform: lowercase;
    padding-top: 10px;
    color: $danger-color;
    position: absolute;
    letter-spacing: 0.5px;
    text-align: center;
  }

  .SpecialOfferHeader {
    top: -18px;
    background-color: $danger-color;
    width: 60%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    text-transform: lowercase;
    font-size: 12px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.5px;
  }

  .PriceBar {
    background-color: $danger-color;
    width: 24%;
    height: 2px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(-19deg);
    top: 10px;
  }
}
