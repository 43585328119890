@import 'assets/scss/variables/colors.scss';
@import 'assets/scss/variables/sizes.scss';

.App {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.main-container {
  width: 100%;

  .main-container-inner {
    width: 100%;
    max-width: $container-max-width;
    margin: 0 auto;
    padding: $container-padding-top $container-padding-vertical 0;
  }
}

.main-container-large {
  width: 100%;

  .main-container-inner {
    width: 100%;
    max-width: $container-large-max-width;
    margin: 0 auto;
    padding: $container-padding-top $container-padding-vertical 0;
  }
}

.main-container,
.main-container-large {
  &.LayoutPage,
  &.ThemePage,
  &.CustomizePage {
    height: calc(100vh - #{$header-height} - #{$wallbar-height});
  }
}

.main-container,
.main-container-large {
  &.LayoutPage,
  &.ThemePage,
  &.CustomizePage {
    margin-top: $wallbar-height;
    overflow-y: scroll;
  }
}

a {
  text-decoration: none;
  color: $brand-color;
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

img {
  user-select: none;
}

:focus {
  outline: none;
}

/* Remove when button component donne */

.btn,
.btn:focus,
.btn:active,
.btn:hover,
.btn-visited {
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.bold {
  font-weight: 600;
}

.align-left {
  text-align: left;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-1 {
  margin-bottom: 10px;
}

// SVG COLORS CLASSES
.brand-stroke-color {
  stroke: $brand-color;
}

.brand-fill-color {
  fill: $brand-color;
}
.brand-fill-color {
  color: $brand-color;
}

.black-stroke-color {
  stroke: $black-color;
}

.black-fill-color {
  fill: $black-color;
}

.dark-grey-stroke-color {
  stroke: $dark-grey-color;
}

.dark-grey-fill-color {
  fill: $dark-grey-color;
}

.grey-stroke-color {
  stroke: $grey-color;
}

.grey-fill-color {
  fill: $grey-color;
}
.grey-fill-color {
  color: $grey-color;
}

.light-grey-stroke-color {
  stroke: $light-grey-color;
}

.light-grey-fill-color {
  fill: $light-grey-color;
}

.danger-stroke-color {
  stroke: $danger-color;
}

.danger-fill-color {
  fill: $danger-color;
}
.danger-text-color {
  color: $danger-color;
}

.info-stroke-color {
  stroke: $info-color;
}

.info-fill-color {
  fill: $info-color;
}

.success-stroke-color {
  stroke: $success-color;
}

.success-fill-color {
  fill: $success-color;
}

.warning-stroke-color {
  stroke: $warning-color;
}

.warning-fill-color {
  fill: $warning-color;
}

.hide {
  opacity: 0;
}

.uppercase {
  text-transform: uppercase;
}

.circle {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  //   box-shadow: 0 0 0 1px #dadada;
}

.NoScreenPage {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: 100px;
  span {
    text-align: center;
    letter-spacing: 0.5px;
  }
}

.text-center {
  text-align: center;
}

.overflow-scroll {
  max-height: 50vh;
  overflow-y: scroll;
  & > div {
    height: auto;
  }
}
