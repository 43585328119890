@import 'assets/scss/variables/colors.scss';
.sidebarTheme {
  width: 100%;
  max-width: 260px;
  min-width: 220px;
  padding-right: 30px;
  //   margin: 0 40px 50px 0;
  user-select: none;
  
  @media screen and (max-width: 1019px) {
    max-width: 280px;
    min-width: 260px;
  }

  .sidebarItem {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: $black-color;
    width: 100%;
    position: relative;
    padding-left: 2px;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    span.available-items {
      float: right;
      font-size: 11px;
      font-weight: 500;
      color: $grey-color;
    }
    &.active {
      color: $brand-color !important;
    }
    &.hide {
      display: none;
    }
    [type='checkbox']:not(:checked),
    [type='checkbox']:checked {
      margin-right: 17px;
    }
    [type='checkbox']:before {
      content: '';
      width: 14px;
      height: 14px;
      position: absolute;
      left: 0;
      top: 4px;
      background-color: white;
      border: solid 1px #979797;
      border-radius: 3px;
    }
    [type='checkbox']:checked:after {
      content: '\2713 ';
      font-size: 18px;
      position: absolute;
      color: $brand-color;
      left: 3px;
      top: 0px;
    }
  }

  .legends {
    & > div {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      svg {
        min-width: 30px;
        user-select: none;
      }
      .round-background-danger {
        width: 30px;
        min-width: 30px;
        height: 30px;
        background: $danger-color;
        border: 2px solid #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          min-width: 0px;
        }
      }
      p {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 0.5px;
        color: $black-color;
      }
    }
    .round-background-brand {
      background: $brand-color;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .categorySidebar {
    border-bottom: 1px dotted #000;
    padding-bottom: 30px;
    margin-bottom: 30px;
    .Title {
      margin-bottom: 10px;
    }
  }
}
