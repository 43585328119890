@import 'assets/scss/variables/colors.scss';

.PreviewPage {
  height: calc(100vh - 60px);
  display: flex;
  //   align-items: center;

  .main-container-inner {
    text-align: center;
  }

  .WallPreview {
    height: 425px;
    width: 754px;
    margin: 0 auto;
    border: solid 1px $grey-color;
    position: relative;
  }

  .Visualizer {
    transform: scale(0.5);
  }

  .Arrow {
    border: 1px solid $brand-color;
    position: absolute;
    height: 30px;
    width: 30px;
    transform: rotate(45deg);
    transform-origin: center;
    top: 190px;
    cursor: pointer;
  }

  .Next {
    right: -50px;
    border-left: 0;
    border-bottom: 0;
  }

  .Previous {
    left: -50px;
    border-right: 0;
    border-top: 0;
  }

  .Buttons {
    display: flex;
    width: 400px;
    justify-content: space-around;
    margin: 0 auto;
    margin-top: 20px;
  }
}
