.Social {
  width: 50%;
  height: 80%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  backface-visibility: hidden;
  .icons {
    svg {
      font-size: 5em;
      margin-right: 0.6em;
    }
  }
  .hashtag {
    max-width: 40%;
    min-width: 20%;
    max-height: 60%;
    min-height: 50%;
  }
}
