@import 'assets/scss/variables/colors.scss';

.ModerationModal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  .ModerationOptionsContainer {
    width: 310px;
    margin: 0 auto;
    .ModerationOption {
      display: flex;
      font-size: 14px;
      align-items: center;
      margin-bottom: 20px;
      letter-spacing: 0.5px;
      position: relative;
      height: 30px;
      &:last-of-type {
        margin-bottom: 0;
      }

      span.label {
        padding-left: 15px;
      }
    }
  }

  .ChipsContainer {
    margin: 0 auto;
  }

  .DestroyModerationContainer {
    width: 100%;
    text-align: center;
    height: 50px;
    .confirm-destroy-moderation-content {
      width: 100%;
      cursor: pointer;
      color: $danger-color;
      letter-spacing: 0.5px;

      small {
        display: block;
        margin-bottom: 15px;
        text-align: center;
      }
    }
  }
}
