@import 'assets/scss/variables/colors.scss';
.Collapser {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  user-select: none;

  .Collapser-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
  }
  .Collapser-content {
    will-change: transform, opacity, height;

    overflow: hidden;
  }
}
