@import 'assets/scss/variables/colors.scss';
@import 'assets/scss/variables/sizes.scss';

.ModerationPage {
  .bold {
    font-weight: 600;
  }

  .brand-color {
    color: $brand-color;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .ModerationNoContentBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: $box-shadow;
    width: 400px;
    position: relative;
    padding: 30px;
    border-radius: 10px;
    margin: 0px auto 30px;
    min-height: 170px;
  }
}

.ModerationPageContent {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  max-width: 920px;
  height: calc(100vh - 146px);
}

.ModerationFilter {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 30px;

  > * {
    color: black;
    transition: color 0.3s ease-in-out;
    height: 25px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    padding: 0 20px;
    user-select: none;

    &::after {
      content: ' ';
      display: block;
      transition: opacity 0.4s ease-in-out, right 0.4s ease-in-out;
      right: 0;
      opacity: 0;
      outline: 1.5px solid $brand-color;
      position: relative;
      width: 100%;
      text-align: center;
      margin: 0 auto;
      margin-top: 5px;
    }

    &.active,
    &:hover {
      color: $brand-color;

      &::after {
        opacity: 1;
        right: 0;
      }
    }
  }
}

.ModerationPageListContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 440px;

  &:last-of-type .ModerationPageList {
    border-left: 1px solid #dadada;
  }
}

.ModerationPageList {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  padding-top: 10px;
}
