.VideoScreen {
  height: 1080px;
  width: 1920px;
  transform-origin: 0 0;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  img {
  }
}
