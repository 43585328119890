@import 'assets/scss/variables/colors.scss';

.PayPage {
  margin: 0 auto;
  text-align: center;
  height: calc(100vh - 60px);
  overflow-y: scroll;

  &.main-container .main-container-inner {
    max-width: 500px;
    margin-bottom: 30px;
  }

  .PayPageHeader {
    display: flex;
    flex-flow: column;
    margin-bottom: 40px;
    color: $brand-color;

    .PayPageHeader-step-name {
      display: inherit;
      justify-content: space-between;
      width: 100%;
      letter-spacing: 0.7px;
    }

    * > [class*='step'] {
      cursor: pointer;
      user-select: none;
      position: relative;

      &.disabled {
        color: $light-grey-color;
      }
    }

    .PayPageHeader-cart-container {
      position: relative;
      height: 30px;
      width: 100%;
      border-bottom: 1px solid $brand-color;
      margin-bottom: 10px;

      .CartIcon {
        transition: all 0.3s ease-in-out;

        &[class*='step'] {
          position: absolute;
          display: block !important;
        }

        &.step_ACTIVATION_INFO {
          left: 0%;
          transform: translateX(40px);
        }

        &.step_PAYMENT_METHOD {
          left: 50%;
          transform: translateX(-50%);
        }

        &.step_PLACE_ORDER {
          left: 50%;
          transform: translateX(600%);
        }
      }
    }

    .SuccessCheckmark {
      position: absolute;
      top: -150%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .PayPageBox {
    min-height: 10px;
    width: 100%;
    box-shadow: $box-shadow;
    text-align: center;
    padding: 30px 20px;
    border-radius: 10px;
    margin-bottom: 40px;
    background-color: white;

    &:last-child() {
      margin-bottom: 0;
    }

    .PayPageBoxInner {
      max-width: 280px;
      margin: 0 auto;

      &.Wider {
        max-width: 330px;
      }
    }
    button:not(.timezone-picker-list-item) {
      cursor: pointer;
      background: transparent;
      border: none;
      text-decoration: underline;
      color: #7617ce;
      font-size: 14px;
      padding: 0;
    }
  }

  .Row {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-content: center;
    height: 30px;

    & > .2-columns {
      width: 48%;
    }

    &.center {
      justify-content: center;
    }
  }

  .Button {
    letter-spacing: 0.5px;
    font-size: 14px;
    width: 200px;
  }

  a {
    font-size: 14px;
    padding-bottom: 10px;
    border-bottom: 1px solid $brand-color;
    height: 20px;
  }
}
