@import 'assets/scss/variables/colors.scss';

.StatsPage {
  overflow-y: scroll;

  svg {
    width: 55px;
  }

  path {
    stroke-width: 5;
  }

  &.main-container {
    margin-bottom: 50px;
    height: calc(100vh - 60px - 50px);
  }

  .FromTo {
    text-align: center;
    font-size: 13px;
    margin-bottom: 50px;
    letter-spacing: 0.5px;
  }

  .main-container-inner {
    text-align: center;
  }

  .ContentAndHashTags {
    text-align: left;
  }

  .ContentLength {
    font-size: 50px;
    font-weight: 600;
  }
  .HashTagCount {
    // font-size: 50px;
    font-weight: 500;
  }

  .StatsPageBox {
    margin-bottom: 30px;

    &:first-of-type {
      margin-bottom: 80px;
    }
    &:last-child {
      margin-bottom: 0;
    }

    .Title {
      margin-bottom: 0;
    }

    .GraphSection {
      width: 100%;

      .HoursTimeline {
        background-color: $black-color;
        padding: 0 4px;
        margin-bottom: 40px;
        user-select: none;
        width: 100%;
        color: #fff;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        text-align: left;

        > * {
          font-size: 11px;
          height: 50px;
          text-align: center;
          writing-mode: vertical-lr;
          text-orientation: sideways;
          color: #fff;
        }
      }

      .ReachInformation {
        background-color: $black-color;
        margin-top: 20px;
        margin-bottom: 50px;
        user-select: none;
        color: #fff;
        font-size: 12px;
        height: 80px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;

        & > *:first-child::after {
          content: '';
          position: absolute;
          top: 10px;
          right: 0;
          bottom: 10px;
          z-index: 0;
          width: 1px;
          background-color: $light-grey-color;
        }

        & > *:last-child {
          z-index: 1;
          position: relative;
          color: #fff;

          &::before {
            content: '';
            position: absolute;
            top: -25px;
            right: 0;
            bottom: -25px;
            left: 0;
            z-index: 0;
            background-color: $brand-color;
          }
        }

        .ReachInformationItem {
          width: 33.3333%;
          padding: 10px;
          position: relative;
        }

        .ReachValue {
          position: relative;
          font-size: 20px;
          font-weight: 400;
          color: #fff;
          margin-bottom: 5px;
        }

        .ReachDescription {
          position: relative;
        }
      }
    }
  }

  .StatsPageBoxContent {
    display: flex;
    color: $dark-grey-color;
    width: 650px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    justify-content: space-around;
    align-items: center;

    &.shadow {
      box-shadow: $box-shadow;
      position: relative;
      justify-content: flex-end;
      //   margin-left: 0;
      width: 730px;
      margin-top: 40px;

      > * {
        width: 30%;
      }
    }

    &.column {
      display: flex;
      flex-direction: column;
    }
    .Button {
      cursor: pointer;
      background: transparent;
      border: none;
      text-decoration: underline;
      color: #7617ce;
      font-size: 14px;
      padding: 0;
      box-shadow: none;
    }
  }

  .DougnutGraph {
    width: 630px !important;
    height: 320px;
    position: absolute;
    left: -230px;
  }

  .TweetsAndPosts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 160px;

    .SocialModiaPostCount {
      font-size: 19px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      font-weight: 500;
      height: 40px;

      svg {
        margin-right: 10px;
      }

      &.Twitter {
        color: $info-color;
      }

      &.Instagram {
        color: $brand-color;
      }
    }
  }

  .FetchedContentList {
    width: 100%;

    .FetchedContentItem {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      svg {
        margin-right: 15px;
      }

      //   &.instagram .FetchedContentItemCount .Count {
      //     background-image: $brand-gradient;
      //   }
      &.twitter .FetchedContentItemCount .Count {
        background: $info-color;
      }

      .FetchedContentItemHashtag {
        font-size: 12px;
        font-weight: 600;
        width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 20px;
        text-align: left;
      }

      .FetchedContentItemCount {
        flex-grow: 1;
        color: #fff;
        height: 30px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 18px;

        .Count {
          width: auto;
          padding-left: 15px;
          padding-right: 15px;
          height: 100%;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          border-radius: 20px;
          background-image: $brand-gradient;
          min-width: 80px;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }
}
