$brand-color: #7617ce;
$brand-color-darken: darken($brand-color, 15%);
$black-color: #333;
$sidebar-background-color: #222;
$dark-grey-color: #4b4b4b;
$grey-color: #949494;
$light-grey-color: #e5e5e5;
//Status colors
$danger-color: #ff3c6b;
$info-color: #00c3d4;
$success-color: #7ac427;
$warning-color: #f39f1c;
// Others colors
$sidebar-inner-bg: #333;
$chip-bg: #e5e5e5;
$chip-bg-hover: darken($chip-bg, 5%);
//Shadows
$box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);

//Gradients
$brand-gradient: linear-gradient(200deg, #be43ec 0%, #6c1dcb 55%, #6c1dcb 100%);
