@import 'assets/scss/variables/colors.scss';

.PublicHomePage {
  background-image: linear-gradient(226deg, #be43ec, #6c1dcb);

  .HomeTitle,
  .HomeLogins {
    height: 100%;
    width: 50%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  .HomeTitle {
    color: #fff;
    padding: 50px;

    .MaintTitle {
      font-size: 40px;
      max-width: 500px;
      margin-bottom: 80px;

      span {
        font-weight: 600;
      }
    }
    .ContentContainer {
      max-width: 500px;
      display: flex;
      flex-flow: row;

      .ContentTitle {
        min-width: 180px;
        letter-spacing: 2px;
        margin-right: 20px;
        font-size: 25px;
        font-weight: 600;
        line-height: 40px;
        display: flex;
        flex-wrap: wrap;
        .title {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            top: 37px;
            left: 0;
            background: white;
          }
        }
      }
      .ContentDescription {
        display: flex;
        flex-flow: column;
        letter-spacing: 0.5px;

        span {
          font-size: 12px;
          margin-bottom: 10px;
          line-height: 18px;
        }
      }
    }
  }

  .HomeLogins {
    background: #fff;
    .TitleLogin,
    .TitleSignup {
      width: 350px;
      border: 1px solid white;
      border-radius: 5px;
      box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
      transition: all ease-in-out 300ms;
      display: flex;
      justify-content: center;
    }
  }
}
