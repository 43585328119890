@import 'assets/scss/variables/colors.scss';

.LayoutThumbnail {
  width: 176px;
  height: 160px;
  display: flex;
  position: relative;
  text-align: center;
  flex-direction: column;
  margin-right: 4.3px;
  cursor: grab;
  user-select: none;

  .LayoutThumbnailImage {
    position: relative;
    background-color: #fff;

    .ButtonContainer {
      &.HideButton {
        opacity: 0;
      }
    }
  }

  &:hover {
    .LayoutThumbnailImage {
      .ThumbnailPreviewer {
        opacity: 1;
      }
    }
    .Button {
      opacity: 1;
    }
  }

  img {
    overflow: hidden;
    border-radius: 10px;
  }

  .LayoutThumbnailTitle {
    text-align: center;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $brand-color;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .bg-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 176px;
    height: 99px;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.21);
    border-radius: 9px;
  }
  .Button {
    min-width: 82px;
    opacity: 0;
  }
  .ThumnailPreviewer-Inner {
    .Button {
      margin-top: 5px;
      background: #fff;
      color: $brand-color;
      border: 1px solid $brand-color;
    }
  }
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  cursor: grabbing;
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
}

.LayoutThumbnail .Uploader + .Button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}