.PlaylistPage {
  height: calc(100vh - 60px);
  overflow-y: scroll;
  .PlaylistScreensDisplayToggle {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 30px;

    svg {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }

    svg + svg {
      margin-left: 20px;
    }
  }
}
