.Logo {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    max-height: 70%;
    max-width: 20%;
  }
}
