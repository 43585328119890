.WallActivatedContainer {
  height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.WallActivated {
  .WallrusActivatedLogo {
    margin: 0 auto;
    width: 160px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .Title {
    margin-bottom: 50px;
  }

  .Dates {
    width: 360px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .Buttons {
    display: flex;
    justify-content: space-around;
    width: 310px;
    margin: 0 auto;
  }

  .Button {
    width: 130px;
  }

  img {
    width: 100%;
  }
}
